@import '../../styles/vars';

.merch {
    &__wrap {
        display: flex;
        @include b(tablet) {
            display: block;
        }
    }

    &__col-sm {
        width: 28%;
        background-size: cover;
        background-position: 50% 50%;
        @include b(tablet) {
            width: 100%;
            height: 200px;
        }
    }

    &__col-lg {
        width: 72%;
        border-right: 3px solid $black;
        @include b(tablet) {
            width: 100%;
            border-right: none;
        }
    }

    &__row {
        border-bottom: 3px solid $black;
        position: relative;

        // &:before {
        //     position: absolute;
        //     content: '';
        //     height: 3px;
        //     background-color: $black;
        //     right: 0;
        //     left: -50vw;
        //     bottom: -3px;
        // }

        &--btn {
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: none;
            @include b(tablet) {
                border-bottom: 3px solid $black;
            }
        }

        &--text {
            padding: 30px;
            text-align: center;
            font-size: 36px;
            letter-spacing: -0.01em;
            line-height: 1.08;
            font-weight: 400;
            @include b(tablet) {
                text-align: left;
            }
            @include b(mobile) {
                padding: 25px 30px;
                font-size: 30px;
            }
        }
    }

    &__button {
        width: 100%;
    }
}