@import '../../styles/vars';

.payment {
    padding: 70px 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrap {
        max-width: 800px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &--state,
        &--card {
            margin: 0 -10px 20px;

            .payment__col {
                padding: 0 10px;

                &:first-child {
                    width: 60%;
                    @include b(mobile) {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }

                &:last-child {
                    width: 40%;
                    @include b(mobile) {
                        width: 100%;
                    }
                }
            }
        }

        &--date {
            margin: 0 -10px 20px;

            .payment__col {
                padding: 0 10px;
                width: 50%;
            }
        }
    }
}