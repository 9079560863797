@import '../../styles/vars';

.box-bg {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 163px;
    border-bottom: 3px solid $black;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 44px;
            height: 100%;
            top: 0;
            left: 0;
            @include b(mobile) {
                width: 100%;
                height: 12px;
            }
    }

    &--head {
        height: auto;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px 40px;
        color: $white;
        text-transform: uppercase;
        @include b(mobile) {
            min-height: 176px;
            padding: 20px 30px;
        }
    }

    &__title {
        font-size: 98px;
        line-height: 1;
        letter-spacing: -.02em;
        font-weight: 400;
        @include b(mobile) {
            font-size: 52px;
        }
    }
}
