@import '../../styles/vars';

.columns-row {
    border-bottom: 3px solid $black;

    &--type0 {
        .columns-row__col {

            &:first-child {
                width: 62%;
                @include b(mobile) {
                    width: 100%;
                }
            }

            &:last-child {
                width: 38%;
                @include b(mobile) {
                    width: 100%;
                }
            }
        }
    }

    &--type01 {
        .columns-row__col {

            &:last-child {
                @include b(mobile) {
                    height: 313px;
                }
            }
        }
    }

    &--type02 {
        .columns-row__wrap {
            flex-direction: row-reverse;
            @include b(mobile) {
                flex-direction: column-reverse;
            }
        }

        .columns-row__col {

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                border-right: 3px solid $black;
                @include b(mobile) {
                    height: 313px;
                    border-right: none;
                    border-bottom: 3px solid $black;
                }
            }
        }
    }

    &--type03 {
        .columns-row__col {

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                    padding-bottom: 20px;
                }
            }

            &:last-child {
                align-items: center;

                @include b(mobile) {
                    padding-top: 0;
                }
            }
        }
    }

    &--type04 {
        .columns-row__col {

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                    padding-bottom: 20px;
                }
            }

            &:last-child {
                align-items: center;

                @include b(mobile) {
                    padding-top: 0;
                }
            }
        }
    }

    &--type05 {
        .columns-row__col {
            background-repeat: repeat;
            background-size: contain;

            &:last-child {
                @include b(mobile) {
                    height: 200px;
                }
            }
        }
    }

    &--type06 {
        .columns-row__wrap {
            @include b(mobile) {
                flex-direction: column;
            }
        }
        

        .columns-row__title {
            margin-bottom: 10px;
        }

        .columns-row__col {

            &:first-child {
                @include b(mobile) {
                    border-bottom: 3px solid $black;
                }
            }

            &:last-child {
                text-align: center;
                @include b(mobile) {
                    border-right: none;
                    border-bottom: none;
                }
            }
        }
    }

    &--type07 {
        .columns-row__wrap {
            @include b(mobile) {
                flex-direction: column-reverse;
            }
        }

        .columns-row__col {

            &:first-child {
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                background-size: cover;
                @include b(mobile) {
                    border-right: none;
                    border-bottom: 3px solid $black;
                    height: 233px;
                }
            }
        }
    }

    &--type08 {
        .columns-row__wrap {
            flex-direction: row-reverse;
            @include b(mobile) {
                flex-direction: column-reverse;
            }
        }

        .columns-row__col {

            &:first-child {
                width: calc(100% - 355px);
                border-right: none;
                @include b(mobile) {
                    width: 100%;
                    border-bottom: none;
                }
            }

            &:last-child {
                width: 355px;
                background-position: 0 50%;
                @include b(mobile) {
                    width: 100%;
                    height: 206px;
                    border-bottom: 3px solid $black;
                    background-position: 50% 50%;
                }
            }
        }

        .columns-row__box {
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }
        } 
    }

    &--type09 {
        .columns-row__wrap {
            @include b(mobile) {
                flex-direction: column-reverse;
            }
        }

        .six-jackpot__label {
            text-align: left;
        }

        .columns-row__col {

            &:first-child {
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                @include b(mobile) {
                    height: 313px;
                    border-bottom: 3px solid $black;
                }
            }
        }
    }

    &--type10 {
        .columns-row__wrap {
            flex-direction: row-reverse;
            @include b(mobile) {
                flex-direction: column-reverse;
            }
        }

        .columns-row__col {
            background-repeat: repeat;
            background-size: contain;

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                min-height: 205px;
                border-right: 3px solid $black;
                @include b(mobile) {
                    min-height: 145px;
                    border-bottom: 3px solid $black;
                    border-right: none;
                }
            }
        }
    }

    &--type11 {
        .columns-row__wrap {
            flex-direction: row-reverse;
            @include b(mobile) {
                flex-direction: column;
            }
        }

        .columns-row__col {
            background-repeat: repeat;
            background-size: contain;

            .columns-row__text {
                margin-bottom: 20px;
            }

            &:first-child {
                border-right: none;
                text-align: center;
                @include b(mobile) {}
            }

            &:last-child {
                border-right: 3px solid $black;
                @include b(mobile) {
                    min-height: 130px;
                }
            }
        }
    }

    &--type12 {
        text-align: center;

        .columns-row__col {
            width: 100%;
            min-height: 315px;
            position: relative;
            overflow: hidden;

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                display: none;
            }
        }
    }

    &--type13 {
        text-align: center;

        .columns-row__col {
            min-height: 315px;
        }
    }

    &--type14 {
        .columns-row__col {
            width: 100%;

            &:first-child {
                border-right: none;
                @include b(mobile) {
                    border-bottom: none;
                }
            }

            &:last-child {
                display: none;
            }
        }
    }

    &__wrap {
        display: flex;
        @include b(mobile) {
            flex-direction: column;
        }
    }

    &__col {
        width: 50%;
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        @include b(mobile) {
            width: 100%;
        }

        &:first-child {
            border-right: 3px solid $black;
            @include b(mobile) {
                border-right: none;
                border-bottom: 3px solid $black;
            }
        }
    }

    &__title {
        font-size: 36px;
        letter-spacing: -0.01em;
        line-height: 1.08;
        font-weight: 400;
        margin-bottom: 40px;
        @include b(mobile) {
            font-size: 30px;
            margin-bottom: 18px;
        }

        &--bold {
            font-weight: 700;
            @include b(mobile) {
                font-weight: 400;
            }
        }
    }

    &__text {

        p {
            margin-bottom: 28px;
            @include b(mobile) {
                margin-bottom: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {

            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 3px;
                    background-color: var(--text-color);
                    left: 0;
                    top: 6px;
                }
            }
        }
    }

    &__box {
        margin-top: 30px;
    }

    &__social {
        font-size: 98px;
        line-height: 1;
        letter-spacing: -0.02em;
        color: $black;
    }
}