@import '../../styles/vars';

.arrow-link {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
    @include b(mobile) {
        font-size: 18px;
        line-height: 1.44;
    }

    &:hover {
        color: $black;
    }

    &__icon {
        min-width: 46px;
        max-width: 46px;
        height: 46px;
        margin-right: 18px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}