@import './styles/vars';
@import './styles/fonts';

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {

    &.dark {
        --bg-color: #5851F5;
        --text-color: #fff !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff;
        }

        .slot-logo {
            --bg-color: #5851F5;
        }

        .inner {
            background: #5950f5 !important;
        }

        .dropdown-select .button {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='10' viewBox='0 0 19 10' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.07354 9.07352L0 0H18.1422L9.07354 9.07352Z' fill='white'/%3E%3C/svg%3E");
        }

        .dd-select-contribution-style .ant-input-number-input {
            border: 2px solid white;
            color: white;
        }

    }

    &.light {
        --text-color: #1D2136 !important;

        .box-bg--head {
            background-color: rgba($black, .2);
        }

        select {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='10' viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.07354 9.07352L0 0H18.1422L9.07354 9.07352Z' fill='black'/%3E%3C/svg%3E");
        }

        .dropdown-select .button {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='10' viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.07354 9.07352L0 0H18.1422L9.07354 9.07352Z' fill='black'/%3E%3C/svg%3E");
        }

        .dd-select-contribution-style .ant-input-number-input {
            border: 2px solid #1e2135;
            color: #1e2135;
        }

        .form-box.form-box--default {
            .form-box__field {
                &:focus {
                    background-color: transparent;
                    border-color: #7D6EFF;
                }
            }
        }
    }

    margin: 0;
    font-family: 'Lato' !important;
    font-size: 18px;
    line-height: 1.11;
    background-color: var(--bg-color);
    color: var(--text-color);
    min-width: 375px;
}

body.light {
    .switch--sm span:before {
        width: 17px !important;
        height: 17px !important;
        top: 2px !important;
        left: 2px !important;
    }

    a {
        color: #1e2135 !important;
    }

    footer a {
        color: #fff !important;
    }

    footer a:hover {
        color: #5851F5 !important;
    }

    footer .footer__nav-link.ukraine-link {
        color: transparent !important;
        background: #fff !important;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #3b3bf4, #ffd147) !important;
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #3b3bf4, #ffd147) !important;
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        -webkit-background-clip: text !important;
        background-clip: text !important;
    }
}

body.dark {
    background: #5851F5 !important;

    .switch--sm span:before {
        left: 26px !important;
    }

    .header__nav.active {
        background: #5851F5 !important;
    }

    a {
        color: #fff !important;
    }

    a:hover {
        color: #1e2135 !important;
    }

    footer .footer__nav-link {
        color: #fff !important;
    }

    footer a:hover {
        color: #5851F5 !important;
    }

    footer .footer__nav-link.ukraine-link {
        color: transparent !important;
        background: #fff !important;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #3b3bf4, #ffd147) !important;
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #3b3bf4, #ffd147) !important;
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        -webkit-background-clip: text !important;
        background-clip: text !important;
    }
}

.digi-font {
    font-family: 'Press Start 2P', cursive;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    line-height: 1.5;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--text-color);
    transition: all .3s;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    overflow: hidden;
}

.container {
    max-width: 1536px;
    margin: 0 auto;
}

main {
    flex-grow: 1;
}

.logo-box {
    border-bottom: 3px solid $black;

    &__wrap {
        padding: 0 30px;
        height: 335px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;

        @include b(mobile) {
            height: 125px;
        }
    }
}

.img {
    max-width: 100%;
    max-height: 100%;
}

.logo-box {
    display: block;
}

.winners-page {
    .logo-box {
        display: none;
    }
}

.loggedin-pg-style {
    .logo-box {
        display: none;
    }
}

.static-content {
    padding: 70px 30px;

    &__box {
        margin-bottom: 60px;
    }

    &__terms {
        display: flex;
        margin: 0 -15px;
        max-width: 700px;

        &-col {
            width: 50%;
            padding: 0 15px;

            p {

                span {
                    color: orange;
                }
            }
        }
    }

    h2 {
        font-size: 36px;
        letter-spacing: -0.01em;
        line-height: 1.08;
        margin-bottom: 40px;

        @include b(mobile) {
            font-size: 30px;
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 24px;
        margin-bottom: 24px;

        @include b(mobile) {
            font-size: 18px;
            margin-bottom: 18px;
        }
    }

    ol.alphabetical {
        list-style: lower-alpha;
        font-size: 24px;
        margin: 0 0 24px;
        padding-left: 24px;

        @include b(mobile) {
            font-size: 18px;
            margin-bottom: 18px;
            padding-left: 18px;
        }

        li {
            margin-bottom: 12px;
            padding-left: 10px;

            @include b(mobile) {
                margin-bottom: 9px;
            }
        }
    }

    ul {
        font-size: 24px;
        margin-bottom: 24px;

        @include b(mobile) {
            font-size: 18px;
            margin-bottom: 18px;
        }

        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 12px;

            @include b(mobile) {
                margin-bottom: 9px;
            }

            &:before {
                position: absolute;
                content: '';
                width: 10px;
                height: 2px;
                background-color: #fff;
                left: 0;
                top: 13px;

                @include b(mobile) {
                    top: 9px;
                }
            }
        }
    }
}

.d-none {
    display: none;
}

.mb-45 {
    margin-bottom: 45px;
}

.text-01 {
    font-weight: 800;
}

.input-01 {
    margin: 15px 0px;
}

.box-text__wrap button {
    margin-left: 0;
    width: auto;
}

.logo-bcch {
    filter: invert(.94) brightness(2);
    height: 125px;
    width: auto;
}

.logo-wck {
    filter: grayscale(100) brightness(.3);
    height: 100px;
    width: auto;
}

.logo-fifa {
    filter: brightness(1.6) grayscale(1);
    height: 100px;
    width: 200px;
    object-fit: contain;
}

.logo-ncf {
    filter: grayscale(100) brightness(.3);
    height: 75px;
    width: auto;
}

.logo-shaq {
    filter: grayscale(1) brightness(0.3);
    width: 200px;
    height: auto;
}

.logo-core {
    filter: brightness(0.8);
    width: 200px;
    height: auto;
}

.logo-comkids {
    filter: brightness(0.3) grayscale(1);
    width: 200px;
    height: auto;
}

.logo-elp {
    filter: brightness(0.12) grayscale(1);
    width: 200px;
    height: auto;
}

.logo-pride {
    filter: grayscale(1) brightness(0.3);
    height: auto;
    width: 200px;
}

.logo-crp {
    filter: brightness(.3) grayscale(1);
    width: 200px;
    height: auto;
}

.logo-hcf {
    filter: grayscale(1) brightness(.95);
    width: 125px;
    height: auto;
}

.logo-mission {
    filter: grayscale(1) brightness(0);
    width: 175px;
    height: auto;
}


.logo-nba {
    filter: grayscale(1) brightness(.95);
    width: 125px;
    height: auto;
}

.logo-nbaf {
    width: 125px;
    height: auto;
}

.logo-rs {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-rs {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-ftx {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-cw {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-skep {
    filter: invert(0) grayscale(1) brightness(1) contrast(1.2);
    width: 100px;
    height: auto;
}

.logo-kse {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-lb {
    filter: invert(0) grayscale(1) brightness(1) contrast(2.5);
    width: 100px;
    height: auto;
    opacity: .8;
}

.logo-sol {
    filter: brightness(0) opacity(0.8);
    width: 200px;
    height: auto;
}

.logo-b2b {
    filter: brightness(0) opacity(0.8) grayscale(1);
    width: 150px;
    height: auto;
}

.logo-tgf {
    width: 150px;
    height: auto;
    filter: grayscale(100);
}

.logo-l4 {
    width: 100px;
    height: auto;
    filter: grayscale(100)brightness(.5);
}

.logo-hc {
    width: 145px;
    height: auto;
    filter: grayscale(100)brightness(.3);
}

.logo-oda {
    width: 150px;
    height: auto;
}

.logo-mf {
    width: 200px;
    height: auto;
}

.logo-fbc {
    width: 200px;
    height: auto;
    filter: grayscale(100) brightness(0.3);
}

.logo-tbwp {
    filter: grayscale(100) brightness(.17);
    width: 225px;
    height: auto;
}

.footer__inner-logo {
    text-align: center;
}

.mascot {
    width: 22%;
    height: auto;
    padding: 15px;
}

footer .mascot {
    filter: invert(1) brightness(100);
}

.footer-logo {
    width: 60%;
}

.page-mascot {
    padding: 0;
    width: 150px;
    padding-left: 15px;
    margin-top: -25px;
    position: absolute;
}

.mobile-only {
    display: none;
}

.mobile-20 {
    font-weight: 400;
    font-size: 21px;
    text-align: left;
}

.wallet-adapter-button-trigger {
    background-color: transparent !important;
    border: 2px solid var(--text-color) !important;
    border-radius: 100px !important;
    color: var(--text-color) !important;
    cursor: pointer;
    display: inline-block;
    font-family: Lato;
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
    padding: 12px 20px;
    height: auto;
    transition: all .3s;
}

.wallet-adapter-button-trigger:hover {
    background-color: #1e2135 !important;
    color: #fff !important;
    box-shadow: 2px 2px 20px rgba(255, 255, 255, .5);
}

.wallet-adapter-button {
    margin-left: 0;
    width: auto;
}

.header__box-rigth .wallet-adapter-button {
    margin-left: 25px;
    width: 100%;
}

.font-bold,
.bold-font {
    font-weight: 800;
}

.img-sun:hover,
.img-moon:hover {
    cursor: pointer;
}

.logo-box {
    position: relative;
}

.link-style {
    a {
        border-bottom: 2px solid;
    }
}

.mt-2rem {
    margin-top: 2rem;
}

.no-arrow {
    svg {
        display: none;
    }

    .arrow-link__text {
        font-weight: 300;
        font-size: 18px;
    }
}

.ventures-page {
    .contributions__title {
        margin-top: 80px;
        margin-bottom: 0px !important;
    }

    .contributions__box {
        text-align: left;

        p {
            font-size: 14px;
            width: 600px;
            margin: auto;
        }
    }

    .ventures-logo-section {
        text-align: center;

        .logo-rs {
            width: 350px;
        }
    }
}

.ukraine-only {
    display: none;
}

.ukraine-flag {
    width: 25px;
    margin-left: 5px;
    margin-top: -5px;
}

.ukraine-link a {
    margin-left: 25px;
    border-bottom: 2px solid;

    &:hover {
        color: #1e2135 !important;
    }
}

.ukraine-page {
    background: #3B3BF4 !important;
    color: #fff !important;

    a:hover {
        color: #ffd147;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #ffffff;
    }

    .ukraine-hide {
        display: none;
    }

    .ukraine-logo {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 25px;
        width: 60px;
        height: auto;
    }

    .ukraine-only {
        display: block;
    }

    .logo-box__wrap {
        height: 425px;
    }

    .logo-box__link .img {
        padding-top: 75px;
        filter: invert(78%) sepia(91%) saturate(325%) hue-rotate(340deg) brightness(110%) contrast(100%);
    }

    .wallet-adapter-button,
    .header__box-rigth .wallet-adapter-button {
        background: #ffd147 !important;
        color: #1D2136 !important;
        box-shadow: none !important;
    }

    .wallet-adapter-button:hover,
    .header__box-rigth .wallet-adapter-button:hover {
        box-shadow: 2px 2px 20px rgba(255, 255, 255, .5);
    }

    .header__nav-theme {
        display: none !important;
    }

    .text-center .box-text__wrap button {
        margin: auto !important;
        margin-top: 25px !important;
    }

    .header__nav {
        background: #3B3BF4 !important;

        a {
            color: #fff !important;
        }

        a:hover {
            color: #ffd147 !important;
        }
    }

    &.dark {
        background: #3B3BF4 !important;
    }

    .main-btn {
        background: #ffd147 !important;
        color: #1e2136 !important;
    }
}

.ukraine-page.dark .header__nav.active {
    background: #3B3BF4 !important;
}

.kryptophant .columns-row__col {
    background-size: 400px !important;
    min-height: 400px !important;
    background-repeat: no-repeat !important;
}

.white-font {
    color: #fff !important;
}

.hide-desktop {
    display: none !important;
}

.columns-row__col .social-icons__link {
    font-size: 34px;
}

.text-left {
    text-align: left;
}

.box-text .box-text__wrap button {
    margin: auto;
}

.more-indicator {
    font-size: 54px;
    position: absolute;
    margin: auto;
    text-align: center;
    width: 100%;
    bottom: 10px;
}

.ticket {
    width: 100%;
    max-width: 375px;
    min-height: 575px;
    margin: 10px;
    border: 3px solid #1e2135 !important;
    border-radius: 35px;
    position: relative;

    .page-mascot {
        position: relative;
        margin: 5px;
        margin-top: 35px;
        margin-bottom: 0;
        padding: 0;
        width: 135px;
    }

    .ticket-header {
        background-color: #1e2135 !important;
        color: #fff !important;
        border-radius: 25px 25px 0px 0px !important;
        padding: 15px 25px;
        padding-bottom: 20px;

        p {
            font-weight: 600;
            font-size: 18px;

            span {
                font-weight: 400;
            }
        }

        .ticket-logo {
            filter: invert(1) brightness(100);
            width: 250px;
            padding: 0.5rem;
            padding-bottom: 0.25rem;
        }
    }

    .ticket-img {
        width: 100px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .ticket-details {
        padding: 10px 10px 25px 10px;

        .via-logo {
            padding-bottom: 0;
        }
    }

    .ticket-numbers {
        font-size: 21px;
        font-weight: 800;
        padding: 10px 10px;
        padding-bottom: 0px;

        span {
            margin: 10px;
        }

        &.bordered {
            border: 3px solid #1e2135 !important;
            padding: 5px;
            border-radius: 10px;
            width: 285px;
            margin: auto;
            margin-top: 10px;
        }
    }

    .ticket-guaranteed {
        padding-top: 15px;
    }

    .ticket-guaranteed-number {
        font-size: 21px;
        font-weight: 800;
        padding: 10px 10px;
        padding-bottom: 0px;
    }

    .ticket-numbers-row-extra {
        padding-top: 10px;
    }

    .view-all {
        padding: 10px;
        padding-bottom: 15px;
    }
}

.text-center {
    text-align: center !important;
}

.event-details {
    p {
        padding-top: 20px;
    }
}

.mb-25px {
    margin-bottom: 25px;
}

.tickets-section {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    align-items: center;
    justify-content: center;

    .all-tickets {
        padding: 0px 10px;
        height: 375px;
        overflow: auto;

        .ticket-numbers {
            border-bottom: 1px solid #1f2135;
            padding-bottom: 10px;
        }
    }

    .event-ticket {
        min-height: 575px;
    }
}

#btn-contribute,
#btn-contribute2 {
    margin-bottom: 2rem;

    button {
        padding: 2.5rem 1.75rem 2.75rem 1.75rem;
    }

    img {
        width: 12rem;
    }
}

#btn-pledge {
    margin: 1rem 0rem 2rem 0rem;

    button {
        padding: 2.5rem 1.75rem 2.75rem 1.75rem;
    }

    img {
        width: 10rem;
    }
}

span.total,
.ant-input-number-input {
    background-color: #1e2135;
    color: #fff;
    padding: 12px 35px;
    font-size: 32px;
    height: auto;
    border-radius: 100px;
}

.thank-you-page-content {
    p {
        font-size: 28px;
        line-height: 58px !important;
        letter-spacing: -0.01em;
        line-height: 1.08;
        font-weight: 400;
        margin-bottom: 40px;
        span.contained {
            background-color: rgba(255,255,255,.25);
            color: #1D2136;
            padding: 12px 35px;
            padding-right: 25px;
            height: auto;
            border-radius: 100px;
            display: inline-flex;
            width: 95%;
            button {
                background: transparent;
                outline: none;
                border: none;
                padding-left: 20px;
                padding-right: 0px;
                opacity: 1;
                font-size: 16px;
                font-weight: 500;
                &:hover, &:active {
                    cursor: pointer;
                    opacity: .75;
                }
                &:active {
                    position: relative;
                    left: 5px;
                }
            }
        }
        .krypto-tickets {
            background-color: rgba(255,255,255,.25);
            color: #1D2136;
            padding: 12px 35px;
            height: auto;
            border-radius: 100px;
        }
        .krypto-address {
            padding: 0px;
            display: block;
            width: 100%;
            max-width: 375px;
            overflow: scroll;
        }
        .krypto-amount{
            padding: 0px;
            display: block;
            width: 100%;
            max-width: 375px;
            overflow: scroll;
        }
    }
}

.make-contribution {
    .ant-space-align-center {
        align-items: center;
        margin: auto;
    }

    .ant-input-number {
        width: 275px;
        background: transparent !important;
        outline: none;
        border: none;
        margin: 0 auto;
    }

    .ant-input-number-handler-wrap {
        position: absolute;
        top: 10%;
        bottom: 10%;
        right: 10%;
        width: 22px;
        height: 80%;
        background: transparent;
    }

    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        right: 4px;
        width: 12px;
        height: 12px;
        color: white;
        font-size: 32px !important;
    }

    .ant-input-number-handler {
        border: none;
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: #fff;
    }

    .ant-input-number-handler:active {
        background: rgba(255, 255, 255, .1);
    }

    .ant-input-number-input {
        padding-right: 65px !important;
    }
}

.ant-input-number-focused {
    outline: none !important;
    box-shadow: none !important;
}

.title-style-02 {
    margin-bottom: 0px !important;
    padding-top: 35px;
}

.box-bg__title {
    color: #fff;
}

.theme-padding {
    padding: 80px 30px;
}

.border-img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.border-imgs {
    opacity: 1;
    // animation: fadeIn 2s forwards;
    // animation-delay: 2s;
}

.gradient-border {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(124deg, #67e282, #bb65ea, #51b8ea, #f3d357, #d65d5d, #5ed0d0, #ff87b9, #bbe85d);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;

    .inner {
        height: 92%;
        margin: auto;
        position: absolute;
        width: 98%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.charity-logo {
    width: 300px;
    object-fit: contain;
    max-width: 100%;
    margin-bottom: 35px;
}

.age-req {
    margin-left: 50px;
    span {
        font-size: 18px;
        font-weight: 800;
        background: #1f2136;
        color: white;
        padding: 13px 10px;
        border-radius: 100px;
        position: relative;
        top: 2px;
    }
}

.charity-pg-style {
    .box-bg {
        display: none;
    }
}

.results-accordion {
	margin-top: 50px;
}

.previous-results {
    h2.h1 {
        font-size: 54px;
        font-weight: 800;
    }
	h1 {
		font-weight: 800;
		color: #1e2135 !important;
		font-size: 54px;
	}
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
	.winners-content {
		padding-bottom: 50px;
	}
    .row {
        display:flex;
        .col {
          flex:1;
          &:last-child {
            margin-left: 1em;
          }
        }
      }
      /* Accordion styles */
      .tabs {
        border-radius: 0px;
        overflow: hidden;
		margin-bottom: 0px;
      }
      .tab {
        width: 100%;
        color: white;
        overflow: hidden;
        &-label {
          display: flex;
          justify-content: space-between;
          padding: 1em;
          background: #1e2135;
          font-weight: bold;
          cursor: pointer;
          /* Icon */
          &:hover {
            background: #1e2135;
          }
          &::after {
            content: "\276F";
            width: 1em;
            height: 1em;
            text-align: center;
            transition: all .35s;
			margin: auto 0;
          }
        }
        &-content {
          max-height: 0;
          padding: 0 1em;
          color: #1e2135;
          background: rgba(225,225,225,.5);
		  //margin-bottom: 25px;
          transition: all .35s;
        }
        &-close {
          display: flex;
          justify-content: flex-end;
          padding: 1em;
          font-size: 0.75em;
          background: #1e2135;
          cursor: pointer;
          &:hover {
            background: #1e2135;
          }
        }
      }
      
      // :checked
      input:checked {
        + .tab-label {
          background: #1e2135;
          &::after {
            transform: rotate(90deg);
          }
        }
        ~ .tab-content {
          max-height: 100%;
          padding: 1em;
		  padding-bottom: 50px;
        }
      }     
	
	  h3 {
		color: white;
		font-size: 28px;
		margin: auto;
		margin-left: 0;
		font-weight: 800;
		font-size: 28px;
	  }
	  .winning-numbers {
		padding: 25px !important;
		margin: 0px 25px !important;
		max-width: 565px !important;
		margin-top: 0px !important;
	  }
	  .winners-content .winning-numbers span {
		margin: 0px 22px;
		font-size: 24px;
	  }
	  .winners-content .rewards-pool {
		padding-top: 0px;
		margin-top: 25px;
		color: #1e2135 !important;
		p {
			color: #1e2135 !important;
		}
		h3 {
			color:#1e2135;
		}
	}
	.past-winner {
		text-align: left;
		h3 {
			color: white;
			text-align: center;
			width: 100%;
			background: #1f2136;
			padding: 25px 15px;
			border-radius: 10px;
			font-size: 28px;
			margin: 25px auto;
			margin-top: 50px;
		}
	}
	.winners {
		width: 100%;
		//max-width: 750px;
		padding: 0px 25px;
		margin: auto;
		.winner {
			position: relative;
		}
		.w-address {
			position: relative;
			padding: 10px 0px;
			padding-bottom: 25px;
			border-bottom: 1px solid #1e2135;
			font-size: 24px;
		}
		.w-amount {
			position: absolute;
			right: 0;
			bottom: 25px;
			font-size: 24px;
			background: rgba(0, 0, 0, 0.15);
			padding: 5px 15px;
			border-radius: 50px;
			font-weight: 400;
			letter-spacing: 0.6px;
		}
	}
}

.prev-btn {
	font-size: 18px;
    opacity: .75;
    font-weight: 600;
	transition: all 0.25s;
	color: #1e2135 !important;
	&:hover {
		opacity: 1;
	}
}

.section-slot-machine {
    position: relative;

    .columns-row__col {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    h2 {
        line-height: 62px;
        font-weight: 800;
        margin-bottom: 0px;

        span {
            font-weight: 600;
            font-size: 26px;
            background: transparent;
            padding: 10px 25px;
            border-radius: 100px;
            border: 2px solid;
        }
    }

    .black-text {
        margin-bottom: 0px;
        color: #1e2136;
        line-height: 54px;
    }

    .slot-machine-container {
        opacity: 0;
        animation: fadeIn 2s forwards;
        animation-delay: 2s;
        padding-bottom: 30px;
    }

    .slot-machine {
        font-family: "Press Start 2P", cursive;
        display: inline-flex;
        margin: auto;
        border-radius: 15px;
        padding: 10px;
        width: 100%;
        border: 0px solid rgba(0, 0, 0, .1);
        //box-shadow: 0px 2px 10px 0px rgba(0,0,0,.075);
        margin-top: 25px;
        margin-bottom: 20px;

        // background: linear-gradient(124deg, #67e282, #bb65ea, #51b8ea, #f3d357, #d65d5d, #5ed0d0, #ff87b9, #bbe85d);
        // background-size: 1800% 1800%;
        // -webkit-animation: rainbow 18s ease infinite;
        // -z-animation: rainbow 18s ease infinite;
        // -o-animation: rainbow 18s ease infinite;
        // animation: rainbow 18s ease infinite;
        .slot-number {
            padding: 0px 25px;
            margin: 11px auto;
            border: 0px solid rgba(0, 0, 0, .1);
            //box-shadow: inset 0px 2px 10px 0px rgba(0,0,0,.3);
            border-radius: 12px;
            font-size: 70px;
            text-align: center;
            width: 15%;
            height: 175px;
            position: relative;
            //background: #1f2136;

            // text-shadow: 2px 2px 20px rgba(0,0,0,.2);
            .slot-value {
                // font-size: min(3vw, 36px);
                margin: 0;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                color: #1e2136;
                font-weight: 900;
                span.letter {
                    position: absolute;
                    text-align: center;
                    left: 0;
                    right: 0;
                    opacity: 1;
                    &.letter-k {
                        animation: fadeOut .5s forwards;
                        animation-delay: 3s;
                    }
                    &.letter-r {
                        animation: fadeOut .5s forwards;
                        animation-delay: 4s;
                    }
                    &.letter-y {
                        animation: fadeOut .5s forwards;
                        animation-delay: 5s;
                    }
                    &.letter-p {
                        animation: fadeOut .5s forwards;
                        animation-delay: 6s;
                    }
                    &.letter-t {
                        animation: fadeOut .5s forwards;
                        animation-delay: 7s;
                    }
                    &.letter-o {
                        animation: fadeOut .5s forwards;
                        animation-delay: 8s;
                    }
                }
            }
        }

        .odometer-k {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 3s;
        }

        .odometer-r {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 4s;
        }

        .odometer-y {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 5s;
        }

        .odometer-p {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 6s;
        }

        .odometer-t {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 7s;
        }

        .odometer-o {
            opacity: 0;
            animation: fadeIn .5s forwards;
            animation-delay: 8s;
        }

        .slot-01 {
            color: #bbe85d;
        }

        .slot-02 {
            color: #ff87b9;
        }

        .slot-03 {
            color: #5ed0d0;
        }

        .slot-04 {
            color: #ff6815;
        }

        .slot-05 {
            color: #bb65ea;
        }

        .slot-06 {
            color: #d65d5d;
        }
    }

    .slot-machine-header {
        padding-bottom: 35px;
        padding-top: 10px;
        opacity: 0;
        animation: fadeIn 2s forwards;
        animation-delay: 2s;
            padding-bottom: 35px;
            padding-top: 60px;
            padding-left: 50px;
            padding-right: 50px;
            position: relative;
            z-index: 5;
        .slot-header {
            position: absolute;
            font-size: 24px;
            font-weight: 800;
            text-align: center;
            width: 100%;
            left: 0;
            right: 0;
            margin-top: 15px;
        }

        .slot-date {
            position: absolute;
            right: 35px;
            font-size: 24px;
            font-weight: 500;
            position: absolute;
            right: 75px;
        }
    }
}

.slot-next {
    font-size: 24px;
    opacity: 0;
    animation: fadeIn 2s forwards;
    animation-delay: 3s;
    position: relative;
    top: 25px;
    z-index: 1;
    color: #1D2136;
    a {
        color: #1D2136 !important;
    }
}

.slot-winners {
    font-size: 18px;
    opacity: 0;
    animation: fadeIn 2s forwards;
    animation-delay: 3s;
    position: relative;
    top: -75px;
    z-index: 1;
    color: #1D2136;
    a {
        color: #1D2136 !important;
    }
}

.slot-logo {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    margin: auto;
    width: 80%;
    animation: fadeOut 2s forwards;
    animation-delay: 2s;
}


@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.no-border {
    border: none !important;
}

.logo-box {
    .columns-row--type12 .columns-row__col {
        min-height: 515px;
    }
}

.border-gradient-container {
    opacity: 0;
    animation: fadeIn 2s forwards;
    animation-delay: 2s;

    .border-gradient {
        background: linear-gradient(124deg, #67e282, #bb65ea, #51b8ea, #f3d357, #d65d5d, #5ed0d0, #ff87b9, #bbe85d);
        background-size: 1800% 1800%;
        -webkit-animation: rainbow 18s ease infinite;
        -z-animation: rainbow 18s ease infinite;
        -o-animation: rainbow 18s ease infinite;
        animation: rainbow 18s ease infinite;
        height: 10px;
    }
}


.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    line-height: 1.1em;
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
    text-align: center;
}



@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }

    50% {
        background-position: 100% 19%
    }

    100% {
        background-position: 0% 82%
    }
}

@-moz-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }

    50% {
        background-position: 100% 19%
    }

    100% {
        background-position: 0% 82%
    }
}

@-o-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }

    50% {
        background-position: 100% 19%
    }

    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }

    50% {
        background-position: 100% 19%
    }

    100% {
        background-position: 0% 82%
    }
}

.columns-row__title--bold {
    color: #1e2135;
}

.login-page {
    .mascot-relative {
        margin-left: -15px;
        position: relative;
        margin-top: 0px;
    }

    .login-form {
        p {
            font-size: 26px;
        }

        input {
            border-radius: 100px;
            padding: 8px 20px;
            font-size: 28px;
            position: relative;
            display: flex;
            margin: auto;
            margin-bottom: 5px;
            background: transparent;
            width: 375px;
            text-align: center;
            border-color: #1d2136;
        }

        input:focus {
            outline: none !important;
        }

        input::placeholder {
            opacity: .75;
            color: var(--text-color);
        }

        button {
            width: 375px;
            text-align: center;
            margin-top: 10px;
            background-color: #1d2136;
            color: #fff;
            border-color: #1d2136;
            font-size: 28px;
        }
    }

    .box-text {
        border-bottom: none;
    }

    .box-text__wrap {
        padding: 80px 0px;
    }
}

.home-page .logo-box {
    display: none;
}

.home-page .logo-box.show-home {
    display: block;
}

.wallet-connection {
    h3 {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 36px;
        padding-left: 8px;
    }

    #wallet-address {
        padding: 0px 45px;
        background: #1f2136;
        border-radius: 100px;
        height: 75px;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .ant-input-number-input {
        padding: 0px 45px;
        background: #1f2136;
        border-radius: 100px;
        height: 75px;
        width: 100%;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .ant-input-number {
        width: 100%;
    }

    .payment__row select {
        padding: 0px 25px;
        padding: 0px 45px;
        background: #1f2136;
        border-radius: 100px;
        height: 75px;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 5px;
        border: none;
        font-size: 32px;
    }
}

.main-btn {
    background-color: #1e2135!important;
    border-radius: 100px!important;
    color: #fff!important;
    transition: all .3s linear !important;
    cursor: pointer !important;
    display: flex !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0px 24px !important;
    align-items: center !important;
    border: none !important;
    justify-content: center;
}

.section-02 {
    p {
        margin: 0px auto 15px auto !important;
    }
    button {
        width: 100%;
        margin-top: 15px !important;
    }
    .payment__row {
        margin-bottom: 0px;
    }
}

.payment {
    padding-top: 0px !important;
}

.img-copy {
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: relative;
    top: -4px;
}

body.light {
    .thank-you-page-content p span.contained, .thank-you-page-content p .krypto-tickets {
        background-color: rgba(0, 0, 0, 0.25);
        color: #fff;
    }
}

@media (min-width: 1026px) and (max-width: 1085px) {
    .ukraine-link a {
        margin-left: 5px;
    }

    .header__text {
        margin-left: 25px !important;
    }

    .section-slot-machine .slot-machine .slot-number .slot-value {
        font-size: 48px;
    }
}


/* logos animation- maybe temporary */

.slot-logo {
    position: relative;
    height: 100%;
    width: 100%;
    margin: unset;
    left: unset;
    right: unset;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    img {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 75%;
    }
}


@-webkit-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @-moz-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @-o-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   #slot-logos img {
     -webkit-animation-name: cf4FadeInOut;
     -webkit-animation-timing-function: ease-in-out;
     -webkit-animation-iteration-count: infinite;
     -webkit-animation-duration: 8s;
   
     -moz-animation-name: cf4FadeInOut;
     -moz-animation-timing-function: ease-in-out;
     -moz-animation-iteration-count: infinite;
     -moz-animation-duration: 8s;
   
     -o-animation-name: cf4FadeInOut;
     -o-animation-timing-function: ease-in-out;
     -o-animation-iteration-count: infinite;
     -o-animation-duration: 8s;
   
     animation-name: cf4FadeInOut;
     animation-timing-function: ease-in-out;
     animation-iteration-count: infinite;
     animation-duration: 8s;
   }
   #slot-logos img:nth-of-type(1) {
     -webkit-animation-delay: 6s;
     -moz-animation-delay: 6s;
     -o-animation-delay: 6s;
     animation-delay: 6s;
   }
   #slot-logos img:nth-of-type(2) {
     -webkit-animation-delay: 4s;
     -moz-animation-delay: 4s;
     -o-animation-delay: 4s;
     animation-delay: 4s;
   }
   #slot-logos img:nth-of-type(3) {
     -webkit-animation-delay: 2s;
     -moz-animation-delay: 2s;
     -o-animation-delay: 2s;
     animation-delay: 2s;
   }
   #slot-logos img:nth-of-type(4) {
     -webkit-animation-delay: 0;
     -moz-animation-delay: 0;
     -o-animation-delay: 0;
     animation-delay: 0;
   }

.align_left {
    margin: auto;
    margin-left: 25px;
}

.header___text {
    font-weight: 700;
    line-height: 1.56;
    padding: 0 15px;
    position: relative;
    text-align: center;
}

.border___top {
    padding: 12px;
    border-top: 3px solid #1D2136;
}

.header__wrap {
    flex-wrap: wrap;
    padding: 18px 30px !important;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.login__btn {
    width: 100px !important;
    margin-left: 5px;
}

.rewards__btn {
    width: 210px !important;
    background-color: transparent !important;
    border: 2px solid #1e2135 !important;
    color: #1e2135 !important;
}

.rewards__btn:hover {
    background-color: #1e2135 !important;
    color: #ffffff !important;
}

.home-page.dark {
    .rewards__btn {
        width: 210px !important;
        background-color: transparent !important;
        border: 3px solid #fff !important;
        color: #fff !important;
    } 
    .rewards__btn:hover {
        background-color: #fff !important;
        border: 3px solid #fff !important;
        color: #1e2135 !important;
    }
}

.header__nav-theme {
    width: auto !important;
}

.footer__inner-logo {
    height: 240px !important;
}

.tix-logo {
    width: 100% !important;
    height: 50px !important;
    margin: 25px auto 25px auto !important;
    object-fit: contain;
}

.mr-5 {
    margin-right: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.margin-auto {
    margin: 0px auto;
}

.mlr-5 {
    margin: 0px 5px !important;
}


/* end of logos animation */

body.dark a.row-link:hover p, body.dark a.row-link p {
    color: #fff !important;
}

.krypto-img-sm {
    width: 70px !important;
    top: 0px !important;
}

.prev-results-bottom {
    padding: 75px 25px;
    text-align: center;
    p {
        font-size: 32px;
    }
}

.sm-text-note {
    font-size: 16px !important;
    margin-top: 5px !important;
}

.ticket {
    .krypto6home {
        width: 100px;
    }
    .mt-10 {
        margin-top: 10px;
    }
}

/* media quesries */

.carousel {
    position: relative;
  }
  
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  /* rtl:begin:ignore */
  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateX(100%);
  }
  
  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateX(-100%);
  }
  
  /* rtl:end:ignore */
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
      transition: none;
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
  .carousel-control-next {
      transition: none;
    }
  }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
  
  .carousel-dark .carousel-control-prev-icon,
  .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100);
  }
  .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
  }
  .carousel-dark .carousel-caption {
    color: #000;
  }


  .fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }

  /* rtl:end:ignore */
.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
      transition: none;
    }
  }

  .crousel-item{
    opacity:0;
    transition: opacity 1s !important;
  }
  .carousel-item.active{
    opacity:1;
    transition: opacity 1s !important;
  }
  
.carousel {
    span, .carousel-indicators {
        display: none;
    }
    h2 span {
        display: block;
        padding: 0px;
        border: none;
    }
    img {
        width: 90%;
        height: 300px;
        object-fit: contain;
    }
    .six, .via {
        width: 50%;
        float: left;
    }
    .via {
        width: 40%;
        left: 4%;
        position: relative;
    }
    .carousel-caption {
        position: absolute;
        right: 2%;
        bottom: 0;
        top: 15%;
        left: unset;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #1D2136;
        text-align: left;
        width: 50%;
        clear: right;
        h2 {
            font-size: 62px;
            line-height: 78px;
            color: #1D2136;
        }
        a {
            color: #1D2136 !important;
        }
    }
}

.section-slot-machine .slot-machine-container, 
.section-slot-machine .slot-machine-header, 
.slot-next {
    display: block;
}



.love-nft .columns-row__col{
    background-size: 400px !important;
    min-height: 700px !important;
    background-repeat: no-repeat !important;
}

.draw-slide {
    .carousel-caption {
        width: 100%;
        left: 0;
        right: 0;
        top: 5%;
        margin: auto;
        text-align: center;
    }
    h2 {
        margin-bottom: 35px;
    }
}

.login-page {
    .box-bg {
        display: none;
    }
}

.login-pg-style {
    .logo-box {
        display: none;
    }
}

.winners-content {
    text-align: center;
    .winning-numbers {
        border: 3px solid ;
        border-radius: 10px;
        margin: 25px auto auto;
        padding: 30px 25px 20px 25px;
        width: 100%;
        max-width: 750px;
        span {
            margin: 0px 22px;
            font-size: 38px;
            font-weight: 800;
            font-family: "Press Start 2P", cursive;
        }
    }
    .rewards-pool {
        margin-top: 50px;
        p {
            margin-bottom: 5px;
        }
        h3 {
            font-size: 48px;
            font-weight: 500;
        }
    }
}

.btn-p {
	padding: 10px;
	a {
		color: #1e2135 !important;
	}
}

.winners-section {
    align-items: normal;
}

.winners-lists {
    .winners {
        padding: 15px;
        padding-top: 0px;
    }
    .winner {
        font-size: 21px;
        padding: 20px 15px;
        text-align: left;
        border-bottom: 1px solid #1e2135 !important;
        &:last-child {
            border: none;
        }
    }
    .winner-list {
        height: auto !important;
        min-height: auto !important;
    }
    .w-amount {
        background: rgba(0,0,0,.15);
        padding: 5px 15px;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: .6px;
    }
}

.section-02 button {
    margin-top: 15px!important;
    width: 100%;
    //background-color: #1f2136;
    color: #1f2136 !important;
    font-size: 32px;
    height: 75px;
    margin-bottom: 10px !important;
    padding: 0 45px;
    border-color: #1f2136 !important;
}

.src-text {
    display: block;
    font-size: 36px;
    padding-left: 8px;
    text-align: left;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
}

.ref-code-select {
    background: #1f2136;
    background-image: none !important;
    border: none;
    border-radius: 100px;
    color: #fff;
    font-size: 32px;
    height: 75px;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 0 45px;
}

.w-auto {
    width: auto !important;
}

.logo-snf {
    width: 125px;
    height: auto;
    filter: grayscale(100) brightness(0.4);
}

.sm-text {
    padding-top: 15px;
    width: 300px;
    margin: auto;
    font-size: 14px;
    max-width: 100%;
}

.w-address {
    word-wrap: break-word;
    margin-bottom: 10px !important;
}

.six-bg-01 {
    height: 450px !important;
    background-size: contain !important;
}

.krypto6 {
    width: 300px;
    margin-top: -15px;
    margin-right: 10px;
}

.winners-section-01 {
    h1 {
        font-weight: 800;
        font-size: 54px;
		color: #1e2135 !important;
    }
    h2 {
        font-weight: 800;
        font-size: 36px;
        margin-top: 45px;
    }
}

.carousel-section {
    .carousel-caption {
        h2 {
            span {
                font-size: 36px;
                position: relative;
                top: -15px;
            }
        }
    }
}

.login__btn {
    line-height: 1.2 !important;
}

.btn-space {
    text-align: right;
    padding-top: 35px;
}

.smaller-font {
    font-size: .8em;
}

.rewards-tabs {
    .tab-label {
        text-align: left;
    }
    .tab-content {
        p {
            text-align: left;
            font-weight: 400;
            font-size: .75em;
        }
    }
}

.content-rewards{
    margin-top: 50px;
    h2 {
        font-size: 1em;
        color: #1D2136;
        font-weight: 600;
        margin-bottom: 25px;
    }
    p {
        font-weight: 400;
    }
}

.fundraiser-heading {
    h2 {
        font-size: 1em;
        font-weight: 400;
    }
}

.kryptophant-10 {
    img {
        width: 50%;
        object-fit: contain;
        max-width: 100%;
        margin-bottom: 0px; 
    }
}

select option {
    color: #fff !important; 
    background-color: #1D2136 !important;
}
  
select option:hover {
    background-color: #1D2136 !important; 
}
  
select option:checked {
    color: #fff !important;
    background-color: #1D2136 !important; 
}

.dropdown-select {
    min-width: 175px;
    height: 55px;
    position: relative;
}

.dropdown-select .button {
    width: 100%;
    height: auto;
    padding: 8.5px 50px 8.5px 20px;
    background-position: calc(100% - 15px) 21px;
    border: 2px solid var(--text-color);
    border-radius: 100px;
    cursor: pointer;
    font-family: "Lato";
    font-size: 26px;
    line-height: 1.3;
    font-weight: 700;
    outline: none;
    background-size: 19px 10px;
    margin: 0;
    height: auto;
    padding: 8.5px 50px 8.5px 20px;
    background-position: calc(100% - 15px) 21px;
    background-repeat: no-repeat;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dropdown-select .dropdown-select-options {
    width: 100%;
    // visibility: hidden;
    position: absolute;
    width: 100%;
    position: absolute;
    border: 2px solid var(--text-color);
    border-radius: 20px;
    top: 60px;
    padding: 10px;
    background: rgba(29, 33, 54, .85);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(29, 33, 54, 0.3);
    color: white;
    z-index: 5;
}

.dropdown-select .dropdown-select-options div {
    font-size: 26px;
    line-height: 1.3;
    border-bottom: 1px solid black;
    padding: 10px;
    opacity: .8;
    overflow: scroll;
}

.dropdown-select .dropdown-select-options div::-web-kit-scrollbar {
    display: none;
}

.dropdown-select .dropdown-select-options div:hover {
    opacity: 1;
    cursor: pointer;
    background: rgba(0,0,0.75);
    border-radius: 5px;
}

.dropdown-select .dropdown-select-options div:last-child {
    border-bottom: none;
}

.full-w {
    width: 100% !important;
}

.font-audiowide {
    font-family: 'Audiowide', cursive !important;
}

.font-russo-one {
    font-family: 'Russo One', sans-serif !important;
}

.set-row {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
}

.set-row .set-col {
    width: 49%;
}

.set-row .set-col.set-gap {
    margin-right: 2%;
}

.btn {
    line-height: 1.25;
}

.dd-select-contribution-style .dropdown-select {
    height: 65px;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.dd-select-contribution-style .dropdown-select .button {
    height: 100%;
    font-size: 32px;
    background-size: 25px 20px;
}

.dd-select-contribution-style .dropdown-select .dropdown-select-options {
    top: 70px;
}

.dd-select-contribution-style .dropdown-select .dropdown-select-options div {
    font-size: 32px;
}

.dd-select-contribution-style .ant-input-number-input {
    font-size: 32px;
    text-align: left;
    font-weight: 800;
    background: transparent;
    transition: none;
}

.dd-select-contribution-style .ant-input-number-handler-wrap {
    position: absolute;
    top: 12%;
    bottom: 10%;
    right: 4%;
}

.make-contribution .ant-input-number-handler-up-inner, .make-contribution .ant-input-number-handler-down-inner {
    color: var(--text-color);
}

.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down, .ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down {
    border-top: 1px solid var(--text-color);
}

.ant-input-number .ant-input-number-handler-wrap span svg {
    color: var(--text-color);
    fill: var(--text-color);
}

.section-02 button {
    margin-top: 25px !important;
    width: 100%;
    background: #1f2136 !important;
    color: white !important;
    font-size: 32px;
    height: 75px;
    margin-bottom: 10px !important;
    padding: 0 45px;
    border-color: #1f2136 !important;
    justify-content: center;
    align-items: center;
}

.btn-style-01 {
    background: transparent;
    color: var(--text-color) !important;
    font-size: 16px !important;
    line-height: 1.25 !important;
    border: none !important;
    border-bottom: 2px solid !important;
    border-radius: 0px !important;
    padding: 5px 0px !important;
}

span.total, .ant-input-number-input {
    color: var(--text-color);
    background: transparent;
    border: 2px solid var(--text-color);
    height: 44px;
    border-radius: 100px;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.text-style-04 {
    font-size: 28px !important;
    letter-spacing: -0.01em !important;
    line-height: 1.08 !important;
    font-weight: 400 !important;
    margin-bottom: 20px !important;
}

.text-style-05{
    font-size: 28px !important;
}

.btn-style-01:hover {
    box-shadow: none !important;
}

.rewards__btn {
    color: var(--text-color) !important;
    border-color: var(--text-color) !important;
}

.rewards__btn:hover {
    color: white !important;
    border-color: transparent !important;
}

.rewards__btn {
    width: 150px !important;
}

.k6-img {
    width: 650px;
    max-width: 90%;
}

.wallet-connection .payment__row select {
    padding: 0px 25px;
    padding: 0px 45px;
    background: transparent;
    border-radius: 100px;
    height: 75px;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 5px;
    border: none;
    font-size: 32px;
    border: 2px solid;
    color: var(--text-color) !important;
}

.dd-select-contribution-style .wallet-adapter-button.wallet-adapter-button-trigger {
    position: relative;
}

.refresh-btn p {
    font-size: 12px;
    margin-top: 12px;
}

.refresh-btn button {
    background-color: #1e2135 !important;
    border-radius: 100px !important;
    color: #fff !important;
    border-color: #1e2135 !important;
    padding: 12px 35px !important;
}

.misc-prize {
    h2 {
        font-weight: 800;
        color: #1e2135 !important;
        font-size: 32px;
    }
    .winners {
        padding: 0px 15px;
        .winner {
            font-size: 21px;
            padding: 20px 15px;
            text-align: left;
            border-bottom: 1px solid transparent !important;
        }
    }
    .w-address {
        font-weight: 800;
        word-wrap: break-word;
        margin-bottom: 0px !important;
        text-align: center;
    }
    .ticket {
        height: auto !important;
        min-height: auto !important;
    }
    .winning-numbers span {
        margin: 0px 10px;
        font-size: 19px;
    }
    .winning-numbers {
        padding: 25px 10px 20px 10px;
        width: 100%;
        max-width: 370px;
        margin-top: 15px;
    }
    h3 {
        font-weight: 800;
        margin-top: 25px;
    }
}

.header-top {
    padding: 12px;
    border-bottom: 3px solid #1D2136;
    text-align: center;
    img {
        width: 100%;
        max-width: 100%;
    }
    p {
        font-size: 24px;
        font-weight: 800;
        padding-bottom: 25px;
    }
}

.krypto6home {
    width: 150px;
    position: relative;
    top: -2px;
    margin: 0px 5px;
}

.krypto6home-sm {
    width: 110px;
    position: relative;
    top: -2px;
    margin: 0px 5px;
}

.custom-333 p {
    margin-top: 25px;
    img {
        width: 100px;
        top: -3px;
    }
}

.pwp {
    img {
        width: 85px;
        top: -2px;
    }
}

.row-3 {
    display: inline-flex;
    width: 100%;
    .col-3 {
        width: 33%;
    }
}

.align-center {
    text-align: center;
}

.partner-logo {
    width: 200px;
}

.valign {
    height: 100%;
    margin: auto;
}

.w-300 {
    width: 300px;
}

.logo-rsnew {
    width: 350px;
    max-width: 100%;
    margin: 50px 0px;
}

.home-page .columns-row--type0 .columns-row__col:last-child {
    width: 100% !important;
}

.krypto6logo1 {
    width: 97% !important;
    max-width: 100%;
    padding-bottom: 25px;
}

.six-jackpot__outer.set-center {
    display: flex;
    justify-content: space-around;
}

.sm-krypto6 {
    width: 85px;
    top: -2px;
}

.gray-img {
    filter: grayscale(1);
}

.krypto6home-link {
    width: 85px;
    position: relative;
    top: 0;
    left: -5px;
    margin: 0px 5px;
    filter: invert(1)brightness(100);
}

.krypto6home-link-header {
    width: auto;
    height: 45px;
    position: relative;
    top: 0;
    left: -5px;
    margin: 0px 5px;
}

.footer .krypto6home-link-header {
    filter: invert(1)brightness(100);
    width: 65px;
}

body.dark .krypto6home-link-header, 
body.dark .krypto6home {
    filter: invert(1)brightness(100);
}

.ml-0 {
    margin-left: 0px !important;
}

.the-six {
    height: 25px;
    margin: 0px 5px;
    position: relative;
    top: -2px;
}

.krypto-six-sm {
    width: 85px;
    height: auto;
}

// .header__nav-body div:nth-child(3) {
//     background-color: lightblue;
// }

body.dark .krypto6home-sm {
    filter: invert(1) brightness(100);
}

body.dark .the-six {
    filter: invert(1) brightness(100);
}

.login-page .login-form input {
    border-radius: 100px;
    padding: 10px 20px;
    font-size: 28px;
    position: relative;
    display: flex;
    margin: auto;
    margin-bottom: 5px;
    background: transparent;
    width: 375px;
    text-align: center;
    outline: none;
    border: 3px solid;
}

.six-bigger {
    height: 35px;
}

.popup {
    position: fixed;
    background: rgba(0,0,0,.85);
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1500;
    width: 100%;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center;
    .popup-content {
        padding: 50px;
        background: white;
        position: relative;
        max-width: 90%;
        width: 850px;
        h2 {
            font-size: 42px;
            letter-spacing: -0.01em;
            line-height: 1.08;
            margin-bottom: 40px;
            font-weight: 700;
        }
    }
    button {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 800;
        border: none;
        background: transparent;
        padding: 25px;
        opacity: .5;
        transition: all 0.3s;
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
    .krypto-01 {
        width: 115px;
        margin-top: 10px;
    }
}

// responsive css below

@media (min-width: 751px) and (max-width: 1025px) {
    .logo-box__wrap img {
        height: 250px !important;
        max-height: unset;
    }

    .logo-box__wrap {
        padding: 0 30px;
        height: 350px !important;
    }

    .text-01 {
        text-align: center !important;
        margin-top: 35px;
    }

    .page-mascot {
        position: relative !important;
        margin-top: 15px;
    }

    .ukraine-page .logo-box__wrap {
        height: 450px !important;
    }

    .ukraine-page .logo-box__link .img {
        padding-top: 0;
        margin-top: 100px;
    }

    .ukraine-page .ukraine-logo {
        top: -25px;
    }

    .login-page .login-form input,
    .login-page .login-form button {
        width: 300px;
    }

    .section-slot-machine .slot-machine .slot-number {
        font-size: 36px;
    }

    .section-slot-machine .slot-machine-container {
        padding-bottom: 0px;
    }

    .section-slot-machine .slot-machine {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .section-slot-machine .slot-machine-header {
        padding-top: 0px;
        position: relative;
        top: 50px;
    }

    .section-slot-machine .slot-machine-header .slot-header {
        font-size: 18px;
    }

    .section-slot-machine .slot-machine-header .slot-date {
        font-size: 18px;
        position: absolute;
        right: 50px;
    }
    .thank-you-page-content p span.contained {
        width: 75%;
    }
    .dd-select-contribution-style h3 {
        font-size: 28px !important;
    }
    .section-50 .six-jackpot__wrap {
        display: block;
    }
    .section-50 .six-jackpot__value {
        margin-bottom: 15px;
    }
    .section-slot-machine .slot-machine-header {
        padding-top: 0px;
        position: relative;
        top: 0px;
    }
    .slot-next {
        font-size: 21px;
    }

    .section-slot-machine .slot-machine-header .slot-header,
    .section-slot-machine .slot-machine-header .slot-date {
        font-size: 21px;
    }
    .logo-box .columns-row--type12 .columns-row__col {
        min-height: 350px;
    }

    .slot-machine-active {
        margin-top: 50px;
    }
    .section-slot-machine .slot-machine-container {
        padding-bottom: 20px;
        margin-top: -25px;
    }
}


@media (min-width: 751px) and (max-width: 768px) {
    .section-slot-machine .slot-machine-header {
        top: 0px;
    }
    .carousel .carousel-caption h2 {
        font-size: 48px;
        line-height: 58px;
    }
    .carousel .carousel-caption {
        top: 25%;
    }
    .love-nft .columns-row__col {
        background-size: 300px !important;
        min-height: 700px !important;
        background-repeat: no-repeat !important;
    }
    .winners-content h2 {
        font-size: 18px;
    }
    .winners-content .winning-numbers span {
        margin: 0px 12px;
        font-size: 18px;
    }
    .winners-section {
        align-items: normal;
        padding-bottom: 35px;
    }
    .w-address {
        font-size: 18px !important;
        font-weight: 600;
    }
    .winners-lists .w-amount {
        font-size: 14px;
    }
    .winners-content .rewards-pool h3 {
        font-size: 36px;
    }
	.previous-results .winning-numbers {
		padding: 10px 25px !important;
		margin: 0px 25px !important;
		max-width: 400px !important;
		margin-top: 0px !important;
	}
	.previous-results .winners-content .winning-numbers span {
		margin: 0px 12px;
		font-size: 18px;
	}
	.previous-results h3 {
		font-size: 18px;
	}
	.previous-results .past-winner h3 {
		font-size: 21px;
	}
    .misc-prize h2 {
        font-size: 32px !important;
        line-height: 1.25;
    }
    .misc-prize .winning-numbers span {
        margin: 0px 10px;
        font-size: 13px !important;
    }
    .slot-machine-active {
        margin-top: 0px;
    }
    .section-slot-machine .slot-machine-container {
        padding-bottom: 20px;
        margin-top: 25px;
    }
}

@media (min-width: 768px) and (max-width: 768px) {
    .logo-box .columns-row--type12 .columns-row__col {
        min-height: 0px;
    }
    .section-slot-machine .slot-machine-header {
        top: 50px;
    }
    .slot-next {
        top: 60px;
    }
    .slot-next {
        font-size: 24px;
    }
    .section-slot-machine .slot-machine-header .slot-header, .section-slot-machine .slot-machine-header .slot-date {
        font-size: 21px;
    }
    .section-slot-machine .slot-machine-header {
        padding-left: 40px;
        padding-right: 40px;
    }
    .section-slot-machine .slot-machine-header .slot-header, .section-slot-machine .slot-machine-header .slot-date {
        font-size: 24px;
    }
    .partner-logo {
        width: 150px;
        margin-bottom: 50px;
    }
    .w-300 {
        width: 225px;
    }
}

@media (min-width: 600px) and (max-width: 750px) {
    .mobile-only {
        display: block;
    }

    .mobile-20 {
        font-size: 20px;
    }

    .logo-box__wrap img {
        height: 250px !important;
        max-height: unset;
    }

    .logo-box__wrap {
        padding: 0 30px;
        height: 300px !important;
    }

    .text-01 {
        text-align: center !important;
        margin-top: 35px;
    }

    .m-border {
        border-right: none !important;
    }

    .page-mascot {
        position: relative !important;
        margin-top: 15px;
        width: 100px;
        padding: 0px;
        margin-bottom: 0px !important;
        padding-left: 0px;
    }

    .mobile-col-reverse-01 .columns-row__wrap {
        flex-direction: column !important;
    }

    .mobile-col-reverse-01 .columns-row__col:last-child {
        height: 313px;
        border-top: 3px solid #1D2136;
    }

    .ukraine-page .logo-box__wrap {
        height: auto !important;
    }

    .ukraine-page .logo-box__link .img {
        padding-top: 0;
        margin-top: 70px;
    }

    .ukraine-page .ukraine-logo {
        top: -55px;
        width: 50px;
    }

    .header__nav {
        top: calc(100% - 52.5px) !important;
    }

    .ukraine-page .header__nav {
        top: calc(100% - 30px) !important;
    }

    .columns-row__col .social-icons__link {
        font-size: 21px;
    }

    .login-page .columns-row__title {
        font-size: 28px;
    }

    .section-slot-machine .black-text {
        margin-bottom: 15px !important;
    }

    .section-slot-machine .slot-machine-header .slot-header {
        position: relative;
    }

    .section-slot-machine .slot-machine-header .slot-date {
        position: relative;
        right: 0px;
    }

    .section-slot-machine .slot-machine-header {
        padding-bottom: 0px;
    }

    .section-slot-machine .slot-machine .slot-number {
        height: 100px;
    }

    .section-slot-machine .slot-machine {
        margin-top: 0px;
    }

    .slot-logo {
        padding: 0 30px;
    }
    .partner-logo {
        width: 150px;
        margin-bottom: 50px;
    }
    .w-300 {
        width: 225px;
    }
}

@media (min-width: 560px) and (max-width: 767px) {
    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none !important;
    }

    select {
        padding: 12px 43px 12px 20px !important;
    }

    .columns-row__title {
        line-height: 1.25 !important;
    }

    .mobile-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .box-text__wrap p {
        font-size: 22px;
    }

    .mobile-20 {
        font-size: 18px;
    }

    .mobile-text-header {
        font-size: 28px !important;
    }

    .logo-box__wrap img {
        max-height: unset;
    }

    .logo-box__wrap {
        height: 175px;
    }

    .text-01 {
        text-align: center !important;
        margin-top: 35px;
    }

    .contributions .contributions__title {
        font-size: 28px;
    }

    .box-bg__title {
        font-size: 42px;
    }

    .footer__inner-logo {
        padding: 15px 30px;
    }

    .mascot {
        width: 25%;
        padding: 0px;
        margin-bottom: 20px !important;
        padding-left: 15px;
    }

    .footer-logo {
        width: 75%;
    }

    .page-mascot {
        position: relative !important;
        margin-top: 50px;
        width: 200px;
        padding: 0px;
        margin-bottom: 0px !important;
        padding-left: 0px;
    }

    .m-02 .box-text__wrap {
        padding-bottom: 0px !important;
    }

    .mobile-col-reverse-01 .columns-row__wrap {
        flex-direction: column !important;
    }

    .mobile-col-reverse-01 .columns-row__col:last-child {
        height: 313px;
        border-top: 3px solid #1D2136;
    }

    .mobile-center {
        text-align: center;
        margin: auto;
    }

    .ukraine-page .m-02 .box-text__wrap {
        padding-bottom: 40px !important;
    }

    .ukraine-page .ukraine-logo {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 15px;
        width: 35px;
        height: auto;
    }

    .ukraine-page .logo-box__link .img {
        padding-top: 50px;
    }

    .ukraine-page .logo-box__wrap {
        height: 250px;
    }

    .ukraine-link a {
        margin-left: 0;
    }

    .ventures-header {
        font-size: 21px !important;
    }

    .ventures-page .contributions__title {
        margin-top: 65px;
        margin-bottom: 26px !important;
    }

    .ventures-page .partners {
        padding: 0px 35px !important;
        margin-bottom: 35px !important;
    }

    .ventures-page .contributions__box p {
        font-size: 14px;
    }

    .header__nav {
        top: calc(100% - 52.5px) !important;
    }

    .ukraine-page .header__nav {
        top: calc(100% - 30px) !important;
    }

    .ventures-page .ventures-logo-section .logo-rs {
        width: 75%;
    }

    .ventures-page .contributions__box p {
        width: 100%;
    }

    .kryptophant .columns-row__col {
        background-size: 300px;
        min-height: auto;
    }

    .footer__inner-col-wide {
        margin: auto;
    }

    .hide-desktop {
        display: block !important;
    }

    .hide-mobile {
        display: none !important;
    }

    .footer__inner-col-wide {
        display: inline-flex;
        width: 100%;
    }

    .footer__nav-link.wm-50 {
        width: 50%;
        margin: auto;
        height: 57px;
        display: flex !important;
    }

    body.light footer .footer__nav-link.ukraine-link {
        color: white !important;
    }

    body.light footer .footer__nav-link.ukraine-link::after {
        content: ' 🇺🇦 ' !important;
        margin-left: 5px !important;
    }

    body.dark footer .footer__nav-link.ukraine-link {
        color: white !important;
    }

    body.dark footer .footer__nav-link.ukraine-link::after {
        content: ' 🇺🇦 ' !important;
        margin-left: 5px !important;
    }

    .header__nav-social a {
        font-size: 26px !important;
    }

    .columns-row__col .social-icons__link {
        font-size: 21px;
    }

    span.total {
        font-size: 22px;
    }

    .theme-padding {
        padding: 50px 30px;
    }

    .m-border-bottom {
        border-bottom: 4px solid;
    }

    .section-slot-machine .columns-row__col {
        padding: 50px 10px;
        min-height: 200px !important;
    }

    .section-slot-machine .slot-machine {
        padding: 5px;
        border-radius: 10px;
    }

    .section-slot-machine .slot-machine .slot-number {
        padding: 0px 5px;
        margin: 2px;
        font-size: 52px;
        width: 16%;
        height: 75px;
    }

    .login-page .login-form input,
    .login-page .login-form button {
        width: 100%;
    }

    .mobile-row-rev .columns-row__wrap {
        display: flex !important;
        flex-direction: column !important;
    }

    .login-page .columns-row--type10 .columns-row__col:last-child {
        border-top: 3px solid #1D2136;
        border-bottom: none;
    }

    .login-page .box-text__wrap {
        padding: 15px 0px;
    }

    .section-slot-machine .black-text {
        margin-bottom: 15px !important;
    }

    .section-slot-machine h2 span {
        font-size: 20px;
    }

    .section-slot-machine .slot-machine .slot-number .slot-value {
        font-size: 21px;
    }

    .section-slot-machine .columns-row__col {
        padding: 25px 20px;
    }

    .section-slot-machine h2 span {
        font-size: 16px;
    }

    .section-slot-machine h2 {
        margin-bottom: 10px;
    }

    .slot-machine {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
    }

    .section-slot-machine .slot-machine-header .slot-header {
        position: relative;
        font-size: 18px;
    }

    .section-slot-machine .slot-machine-header .slot-date {
        position: relative;
        right: 0px;
        font-size: 18px;
    }

    .section-slot-machine .slot-machine-header {
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .section-slot-machine .slot-machine .slot-number {
        height: 100px;
    }

    .section-slot-machine .slot-machine {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .slot-logo {
        padding: 0 30px;
    }

    .slot-next {
        font-size: 14px;
    }

    .slot-next {
        top: -20px;
    }

    .section-slot-machine .slot-machine-container {
        margin-top: -15px;
        padding-bottom: 0px;
    }

    /* thickest mobile */
    .border-img {
        position: absolute;
        width: 103%;
        left: -1.2%;
        height: 100%;
    }

    .wallet-connection .payment__row select {
        font-size: 18px;
        padding: 0px 25px !important;
        height: 50px;
    }

    .wallet-connection #wallet-address,
    .wallet-connection .ant-input-number-input {
        height: 50px;
        padding: 0px 25px;
        font-size: 18px;
    }

    .wallet-connection h3 {
        font-size: 21px;
    }
    .carousel img {
        width: 80%;
        height: 200px;
    }
    .carousel .six, .carousel .via {
        width: 45%;
        float: left;
        padding: 25px;
        left: 5%;
    }
    .carousel .carousel-caption {
        width: 40%;
        top: 20%;
        right: 6%;
    }
    .carousel .carousel-caption h2 {
        font-size: 26px;
        line-height: 38px;
    }
    .carousel .via {
        width: 45%;
        left: 5%;
    }
    .slot-winners {
        font-size: 14px;
        top: -45px;
    }
    .slot-next {
        top: -25px;
    }
    .section-slot-machine .slot-machine-container {
        margin-top: -20px;
        padding-bottom: 0px;
        position: relative;
        top: -4px;
    }
    .carousel-section .box-text__wrap {
        padding: 10px !important;
    }
    .carousel-section .carousel-caption h2 span {
        font-size: 18px;
        position: relative;
        top: -8px;
    }
    .carousel-section .carousel .carousel-caption h2 {
        font-size: 26px;
        line-height: 36px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .carousel-section .carousel .carousel-caption a {
        font-size: 18px;
    }
    .carousel-section .love-slide a {
        position: relative;
        top: -25px;
    }
    .carousel-section .love-slide .carousel-caption h2 {
        margin-top: -10px !important;
        margin-bottom: 15px !important; 
    }
    .carousel-section .draw-slide h2 {
        font-size: 21px;
    }
    .krypto6 {
        width: 62%;
    }
    .winners-section-01 .winners-content .winning-numbers {
        padding: 25px 5px !important;
    }
    .winners-section-01 .winners-content .winning-numbers span {
        margin: 0px 10px;
        font-size: 16px;
    }
    .fundraiser-heading {
        padding-bottom: 40px !important;
    }
    .charity-logo {
        width: 250px;
    }
    .kryptophant-10 img {
        width: 100%;
        padding-bottom: 35px;
    }
    .dropdown-select .button, .dd-select-contribution-style .dropdown-select .button {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select {
        height: 55px !important;
    }
    .dd-select-contribution-style .dropdown-select .button {
        background-size: 25px 10px;
    }
    .section-02 button {
        margin-top: 15px !important;
    }
    .dropdown-select .dropdown-select-options div {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select .dropdown-select-options {
        top: 60px;
    }
    .text-style-05, .text-style-04 {
        font-size: 21px !important;
    }
    .wallet-connection h3 {
        font-size: 21px !important;
    }
    .box-text .box-text__wrap button {
        margin: auto;
        font-size: 21px !important;
        height: 60px !important;
        padding: 0 25px !important;
    }
    .slot-next {
        top: 0px;
    }
    .partner-logo {
        width: 150px;
        margin-bottom: 50px;
    }
    .w-300 {
        width: 225px;
    }
}

@media only screen and (max-width: 559px) {
    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none !important;
    }

    .slot-logo img.krypto-via {
        margin-top: -5px;
        height: 50px;
    }
    .slot-logo img.krypto-six {
        height: 100px;
    }

    select {
        padding: 12px 43px 12px 20px !important;
    }

    .columns-row__title {
        line-height: 1.25 !important;
    }

    .mobile-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .box-text__wrap p {
        font-size: 22px;
    }

    .mobile-20 {
        font-size: 18px;
    }

    .mobile-text-header {
        font-size: 28px !important;
    }

    .logo-box__wrap img {
        max-height: unset;
    }

    .logo-box__wrap {
        height: 175px;
    }

    .text-01 {
        text-align: center !important;
        margin-top: 35px;
    }

    .contributions .contributions__title {
        font-size: 28px;
    }

    .box-bg__title {
        font-size: 42px;
    }

    .footer__inner-logo {
        padding: 15px 30px;
    }

    .mascot {
        width: 25%;
        padding: 0px;
        margin-bottom: 20px !important;
        padding-left: 15px;
    }

    .footer-logo {
        width: 75%;
    }

    .page-mascot {
        position: relative !important;
        margin-top: 50px;
        width: 200px;
        padding: 0px;
        margin-bottom: 0px !important;
        padding-left: 0px;
    }

    .m-02 .box-text__wrap {
        padding-bottom: 0px !important;
    }

    .mobile-col-reverse-01 .columns-row__wrap {
        flex-direction: column !important;
    }

    .mobile-col-reverse-01 .columns-row__col:last-child {
        height: 313px;
        border-top: 3px solid #1D2136;
    }

    .mobile-center {
        text-align: center;
        margin: auto;
    }

    .ukraine-page .m-02 .box-text__wrap {
        padding-bottom: 40px !important;
    }

    .ukraine-page .ukraine-logo {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 15px;
        width: 35px;
        height: auto;
    }

    .ukraine-page .logo-box__link .img {
        padding-top: 50px;
    }

    .ukraine-page .logo-box__wrap {
        height: 250px;
    }

    .ukraine-link a {
        margin-left: 0;
    }

    .ventures-header {
        font-size: 21px !important;
    }

    .ventures-page .contributions__title {
        margin-top: 65px;
        margin-bottom: 26px !important;
    }

    .ventures-page .partners {
        padding: 0px 35px !important;
        margin-bottom: 35px !important;
    }

    .ventures-page .contributions__box p {
        font-size: 14px;
    }

    .header__nav {
        top: calc(100% - 52.5px) !important;
    }

    .ukraine-page .header__nav {
        top: calc(100% - 30px) !important;
    }

    .ventures-page .ventures-logo-section .logo-rs {
        width: 75%;
    }

    .ventures-page .contributions__box p {
        width: 100%;
    }

    .kryptophant .columns-row__col {
        background-size: 300px;
        min-height: auto;
    }

    .footer__inner-col-wide {
        margin: auto;
    }

    .hide-desktop {
        display: block !important;
    }

    .hide-mobile {
        display: none !important;
    }

    .footer__inner-col-wide {
        display: inline-flex;
        width: 100%;
    }

    .footer__nav-link.wm-50 {
        width: 50%;
        margin: auto;
        height: 57px;
        display: flex !important;
    }

    body.light footer .footer__nav-link.ukraine-link {
        color: white !important;
    }

    body.light footer .footer__nav-link.ukraine-link::after {
        content: ' 🇺🇦 ' !important;
        margin-left: 5px !important;
    }

    body.dark footer .footer__nav-link.ukraine-link {
        color: white !important;
    }

    body.dark footer .footer__nav-link.ukraine-link::after {
        content: ' 🇺🇦 ' !important;
        margin-left: 5px !important;
    }

    .header__nav-social a {
        font-size: 26px !important;
    }

    .columns-row__col .social-icons__link {
        font-size: 21px;
    }

    span.total {
        font-size: 22px;
    }

    .theme-padding {
        padding: 50px 30px;
    }

    .m-border-bottom {
        border-bottom: 4px solid;
    }

    .section-slot-machine .columns-row__col {
        padding: 50px 10px;
        min-height: 200px !important;
    }

    .section-slot-machine .slot-machine {
        padding: 5px;
        border-radius: 10px;
    }

    .section-slot-machine .slot-machine .slot-number {
        padding: 0px 5px;
        margin: 2px;
        font-size: 52px;
        width: 16%;
        height: 75px;
    }

    .login-page .login-form input,
    .login-page .login-form button {
        width: 100%;
    }

    .mobile-row-rev .columns-row__wrap {
        display: flex !important;
        flex-direction: column !important;
    }

    .login-page .columns-row--type10 .columns-row__col:last-child {
        border-top: 3px solid #1D2136;
        border-bottom: none;
    }

    .login-page .box-text__wrap {
        padding: 15px 0px;
    }

    .section-slot-machine .black-text {
        margin-bottom: 15px !important;
    }

    .section-slot-machine h2 span {
        font-size: 20px;
    }

    .section-slot-machine .slot-machine .slot-number .slot-value {
        font-size: 21px;
    }

    .section-slot-machine .columns-row__col {
        padding: 25px 20px;
    }

    .section-slot-machine h2 span {
        font-size: 16px;
    }

    .section-slot-machine h2 {
        margin-bottom: 10px;
    }

    .slot-machine {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
    }

    .section-slot-machine .slot-machine-header .slot-header {
        position: relative;
        font-size: 18px;
    }

    .section-slot-machine .slot-machine-header .slot-date {
        position: relative;
        right: 0px;
        font-size: 18px;
    }

    .section-slot-machine .slot-machine-header {
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .section-slot-machine .slot-machine .slot-number {
        height: 100px;
    }

    .section-slot-machine .slot-machine {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .slot-logo {
        padding: 0 30px;
    }

    .slot-next {
        font-size: 14px;
    }

    .slot-next {
        top: -20px;
    }

    .section-slot-machine .slot-machine-container {
        margin-top: -15px;
        padding-bottom: 0px;
    }

    /* thickest mobile */
    .border-img {
        position: absolute;
        width: 103%;
        left: -1.25%;
        height: 100%;
    }

    .wallet-connection .payment__row select {
        font-size: 18px;
        padding: 0px 25px !important;
        height: 50px;
    }

    .wallet-connection #wallet-address,
    .wallet-connection .ant-input-number-input {
        height: 50px;
        padding: 0px 25px;
        font-size: 18px;
    }

    .wallet-connection h3 {
        font-size: 21px;
    }
    .thank-you-page-content p {
        font-size: 21px;
        line-height: 48px !important;
    }
    .thank-you-page-content p span.contained {
        padding: 6px 25px;
        padding-right: 25px;
    }
    .thank-you-page-content p span.contained {
        width: 67%;
    }
    .thank-you-page-content p {
        font-size: 20px;
    }
    .carousel img {
        width: 90%;
        height: 200px;
    }
    .carousel .six, .carousel .via {
        width: 45%;
        float: left;
        padding: 25px;
    }
    .carousel .carousel-caption {
        width: 50%;
        top: 20%;
        right: 6%;
    }
    .carousel .carousel-caption h2 {
        font-size: 26px;
        line-height: 38px;
    }
    .carousel .via {
        width: 45%;
        left: 0px;
    }
    .love-nft .columns-row__col {
        background-size: 300px !important;
        min-height: 500px !important;
        background-repeat: no-repeat !important;
    }
    .header___text {
        font-size: 13.5px;
    }
    .footer__inner-top {
        display: none !important;
    }
    .header .btn--lg {
        padding: 10px 10px;
    }
    .rewards__btn {
        font-size: 14px !important;
        width: 175px !important;
    }
    .login__btn {
        font-size: 14px !important;
        // width: 75px !important; 
    }
    .draw-slide .carousel-caption {
        width: 75% !important;
        left: 0;
        right: 0 !important;
        top: 0% !important;
        margin: auto;
        text-align: center;
    }
    .header__nav-theme {
        width: 50% !important;
    }
    .footer__inner-col {
        width: 0% !important;
        margin-left: -60px !important;
    }
    .footer__inner-logo {
        width: 100% !important;
        height: auto !important;
        padding: 30px 30px !important;
    }
    .header__nav {
        padding-bottom: 0px !important;
    }
    .footer__nav-link.wm-50 {
        width: 100%;
    }
    .header___text {
        padding: 0px !important;
    }
    .border___top {
        padding: 12px 0px !important;
    }
    .header___text {
        font-size: 12px !important;
    }
    .box-text .box-text__wrap button {
        margin: auto;
        font-size: 18px !important;
        height: 50px  !important;
        padding: 0 25px !important;
    }
    .ref-code-select {
        font-size: 18px !important;
        height: 50px !important;
        padding: 0 25px !important;
    }
    .wallet-connection h3 {
        font-size: 16px !important;
        font-weight: 600 !important;
    }
    .section-02 p {
        font-size: 14px !important;
    }
    .section-02 button {
        font-size: 18px !important;
        padding: 0 15px !important;
    }
    .winners-content h2 {
        font-size: 18px;
    }
    .winners-content .winning-numbers span {
        margin: 0px 12px;
        font-size: 18px;
    }
    .winners-section {
        align-items: normal;
        padding-bottom: 35px;
    }
    .w-address {
        font-size: 18px !important;
        font-weight: 600;
    }
    .winners-lists .w-amount {
        font-size: 14px;
    }
    .winners-content .rewards-pool h3 {
        font-size: 36px;
    }
    .winners-lists .winner-list {
        height: auto !important;
        min-height: auto;
    }
    .winners-section-01 .winners-content .winning-numbers span {
        margin: 0px 10px;
        font-size: 16px;
    }
    .winners-section-01 .winners-content .winning-numbers {
        padding: 25px 15px;
    }
    .winners-section-01 {
        padding-bottom: 50px;
    }
    .winners-section-03 h3 {
        font-weight: 800;
        font-size: 21px;
    }
    .winners-section-03 p {
        font-size: 18px;
    }
    .winners-section-03 {
        padding-bottom: 50px;
    }
    .winners-section-01 .winners-content h2 {
        font-size: 24px;
    }
    .krypto6 {
        width: 90%;
        margin-top: 0px;
        margin-right: 0px;
    }
    .winners-section-01 h1 {
        font-weight: 800;
        font-size: 35px;
    }
    .jackpot-selects {
        display: block !important;
        width: 300px !important;
        margin: auto !important;
    }
    .jackpot-selects .six-jackpot__value {
        margin-bottom: 10px !important;
    }
    .jackpot-selects .six-jackpot__value {
        margin-right: 0px !important;
    }
    .jackpot-selects  .six-jackpot__select {
        width: 48.25% !important;
    }
    .slot-winners {
        font-size: 14px;
        top: -45px;
    }
    .slot-next {
        top: -25px;
    }
    .section-slot-machine .slot-machine-container {
        margin-top: -20px;
        padding-bottom: 0px;
        position: relative;
        top: -4px;
    }
    .carousel-section .box-text__wrap {
        padding: 10px !important;
    }
    .carousel-section .carousel-caption h2 span {
        font-size: 18px;
        position: relative;
        top: -8px;
    }
    .carousel-section .carousel .carousel-caption h2 {
        font-size: 26px;
        line-height: 36px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .carousel-section .carousel .carousel-caption a {
        font-size: 18px;
    }
    .carousel-section .love-slide a {
        position: relative;
        top: -25px;
    }
    .carousel-section .love-slide .carousel-caption h2 {
        margin-top: -10px !important;
        margin-bottom: 15px !important; 
    }
    .carousel-section .draw-slide h2 {
        font-size: 21px;
    }
    .krypto6 {
        width: 80%;
    }
    .winners-section-01 .winners-content .winning-numbers {
        padding: 25px 5px !important;
    }
	.previous-results h2.h1 {
		font-size: 35px;
	}
	.previous-results h3 {
		font-size: 21px;
	}
	.previous-results .winning-numbers {
		padding: 8px 12px 10px !important;
		margin: 0px !important;
		max-width: 565px !important;
		margin-top: 0px !important;
	}
	.previous-results .winners-content .winning-numbers span {
		margin: 0px 6px;
		font-size: 14px;
	}
	.previous-results .past-winner h3 {
		text-align: center;
		width: 100%;
		padding: 15px 15px;
		border-radius: 5px;
		font-size: 21px;
		margin: 25px auto;
		margin-top: 25px;
	}
	.previous-results .winners {
		width: 100%;
		padding: 0px;
	}
	.previous-results .winners .w-address {
		position: relative;
		padding: 10px 0px;
		padding-bottom: 65px;
		font-size: 18px !important;
	}
	.previous-results .winners .w-amount {
		position: absolute;
		right: auto;
		left: 0;
		bottom: 25px;
		font-size: 18px;
	}
	.tab-content h2 {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	.winners-content h1 {
		font-weight: 800;
		font-size: 35px;
		color: #1e2135 !important;
	}
    .previous-results .tab-label {
        height: 100px;
        overflow: hidden;
    }
    .rewards-tab-table .tab-label {
        height: auto;
        overflow: hidden;
        text-align: left;
    }
    .content-rewards h2 {
        font-size: .8em;
    }
    .content-rewards p {
        font-size: .65em;
    }
    .fundraiser-heading {
        padding-bottom: 40px !important;
    }
    .charity-logo {
        width: 250px;
    }
    .kryptophant-10 img {
        width: 100%;
        padding-bottom: 35px;
    }
    .dropdown-select .button, .dd-select-contribution-style .dropdown-select .button {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select {
        height: 55px !important;
    }
    .dd-select-contribution-style .dropdown-select .button {
        background-size: 25px 10px;
    }
    .section-02 button {
        margin-top: 15px !important;
    }
    .dropdown-select .dropdown-select-options div {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select .dropdown-select-options {
        top: 60px;
    }
    .text-style-05, .text-style-04 {
        font-size: 21px !important;
    }
    .wallet-connection h3 {
        font-size: 21px !important;
    }
    .box-text .box-text__wrap button {
        margin: auto;
        font-size: 21px !important;
        height: 60px !important;
        padding: 0 25px !important;
    }
    .k6-img {
        max-width: 100%;
    }
    .partner-logo {
        width: 150px;
        margin-bottom: 50px;
    }
    .w-300 {
        width: 225px;
    }
}

@media only screen and (max-width: 550px) {
    .ukraine-page .logo-box__wrap {
        height: 200px;
    }
    .age-req {
        margin-left: 38px !important;
    }
    .age-req span {
        font-size: 14px !important;
        padding: 14px 12px !important;
        border-radius: 100px !important;
        position: relative !important;
        top: 1px !important;
    }
    header .rewards__btn {
        font-size: 14px !important;
        width: 100% !important;
        padding: 10px 5px !important;
    }
    .dropdown-select .button {
        font-size: 20px;
        text-align: left;
        padding: 12px 20px;
    }
    .dropdown-select {
        min-width: 145px;
    }
    .dropdown-select .dropdown-select-options div {
        font-size: 20px;
        text-align: left;
    }
    .dropdown-select .button, .dd-select-contribution-style .dropdown-select .button {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select {
        height: 55px !important;
    }
    .dd-select-contribution-style .dropdown-select .button {
        background-size: 25px 10px;
    }
    .section-02 button {
        margin-top: 15px !important;
    }
    .dropdown-select .dropdown-select-options div {
        font-size: 20px !important;
    }
    .dd-select-contribution-style .dropdown-select .dropdown-select-options {
        top: 60px;
    }
    .text-style-05, .text-style-04 {
        font-size: 21px !important;
    }
    .wallet-connection h3 {
        font-size: 21px !important;
    }
    .box-text .box-text__wrap button {
        margin: auto;
        font-size: 21px !important;
        height: 60px !important;
        padding: 0 25px !important;
    }
    .k6-img {
        max-width: 100%;
    }
    .misc-prize h2 {
        font-size: 32px !important;
        line-height: 1.25;
    }
    .misc-prize .winning-numbers span {
        margin: 0px 10px !important;
        font-size: 14px !important;
    }
    .misc-prize h2 {
        font-size: 28px !important;
        line-height: 1.35;
        width: 80%;
        margin: auto;
        margin-bottom: 45px;
    }
    .misc-prize .ticket {
        height: auto !important;
        min-height: auto !important;
        margin: 15px 0px;
        margin-bottom: 0px;
    }
    .header-top img {
        width: 325px;
        max-width: 100%;
    }
    .slot-next {
        top: 0px;
    }
    .section-slot-machine .slot-machine-header .slot-header {
        font-size: 16px;
    }
    .section-slot-machine .slot-machine-header .slot-date {
        position: relative;
        right: 0px;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .slot-winners {
        font-size: 14px;
        top: -40px;
    }
    .mobile-20 .krypto6home {
        width: 150px;
        position: relative;
        top: 0px;
        margin: 0px;
    }
    .row-3 {
        display: block;
        width: 100%;
    }
    .row-3 .col-3 {
        width: 100%;
    }
    .partner-logo {
        width: 150px;
        margin-bottom: 50px;
    }
    .w-300 {
        width: 225px;
    }
    .krypto6home {
        width: 110px;
    }
    .section-slot-machine .slot-machine-header {
        padding-bottom: 0px;
        padding-top: 20px;
    }
    .section-slot-machine .slot-machine-container {
        margin-top: -30px;
        padding-bottom: 30px;
        position: relative;
        top: 20px;
    }
    .header-top p {
        font-size: 16px;
        font-weight: 800;
        padding-bottom: 5px;
    }
    .section-slot-machine .slot-machine-header {
        padding-left: 15px;
        padding-right: 15px;
    }
    .krypto6home-link-header {
        width: 75px;
    }
    .prev-results-bottom p {
        font-size: 21px;
    }
    .ticket .ticket-header p {
        font-weight: 600;
        font-size: 16px;
    }
    .ticket .ticket-header {
        padding: 15px 25px;
        padding-bottom: 20px;
    }
    .sm-text-note {
        font-size: 14px !important;
        margin-top: 5px !important;
    }
    .section-slot-machine .slot-machine-header .slot-header {
        font-size: 15px;
    }
    .krypto6home-link-header {
        width: auto;
        height: 20px;
    }
    .header__nav-body div:nth-child(3) img {
        height: 23px;
    }
}

@media only screen and (max-width: 375px) {
    .thank-you-page-content p {
        font-size: 18px;
    }
    .thank-you-page-content p span.contained {
        width: 67%;
    }
}