.six-jackpot__select {
    display: inline-flex;
}

.payment {
    padding-top: 25px;
    padding-bottom: 0px;
}

.section-02 {
    p {
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        margin: 10px auto;
    }
}

@media only screen and (max-width: 559px) {
    .columns-row__title {
        font-size: 26px;
        margin-bottom: 30px;
    }
    .payment {
        padding-left: 0px;
        padding-right: 0px;
    }
    .section-02 p {
        font-size: 16px;
    }
    .columns-row__title {
        text-align: center;
    }
    .center-mobile {
        text-align: center;
    }
}