@import '../../styles/vars';

.contact {
    &__wrap {
        display: flex;
        border-bottom: 3px solid $black;
        @include b(tablet) {
            display: block;
        }
    }

    &__col-sm {
        width: 28%;
        background-size: cover;
        background-position: 50% 50%;
        @include b(tablet) {
            width: 100%;
            height: 350px;
            background-position: 50% 90%;
        }
    }

    &__col-lg {
        width: 72%;
        border-right: 3px solid $black;
        @include b(tablet) {
            width: 100%;
            border-right: none;
        }
    }

    &__row {
        border-bottom: 3px solid $black;

        &--btn {
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: none;
            @include b(tablet) {
                border-bottom: 3px solid $black;
            }
        }
    }

    &__button {
        width: 100%;
    }

    &__support {
        margin-top: 20px;

        a {
            border-bottom: 1px solid var(--text-color);
        }
    }
}