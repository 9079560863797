@import '../../styles/vars';

.contributions-jackpot {
    border-bottom: 3px solid $black;

    &__outer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 48px 30px;
        @include b(tablet) {
            display: block;
            text-align: center;
        }
    }

    &__title {
        font-size: 98px;
        line-height: 1;
        color: $black;
        @include b(tablet) {
            margin-bottom: 30px;
        }
        @include b(mobile) {
            font-size: 58px;
        }
    }

    &__info {
        font-size: 36px;
        line-height: 1.08;
        letter-spacing: -0.01em;
        width: 50%;
        @include b(tablet) {
            width: 100%;
            margin-bottom: 26px;
            order: 1;
        }
        @include b(mobile) {
            font-size: 28px;
            line-height: 1.14;
            letter-spacing: -0.01em;
        }
    }

    &__label {
        text-align: center;
        margin-bottom: 10px;
    }

    &__wrap {
        display: flex;
        align-items: center;
        @include b(tablet) {
            justify-content: center;
        }
    }

    &__value {
        border: 2px solid var(--text-color);
        height: 44px;
        border-radius: 100px;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        padding: 5px 20px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        @include b(mobile) {
            font-size: 20px;
        }
    }
}

.contributions {
    
    &__outer {
        padding: 0 30px 70px;
    }

    .partners {
        padding-bottom: 70px;
        @include b(tablet) {
            padding-top: 70px;
            margin-bottom: 0;
        }
    }

    .contributions__title {
        font-size: 36px;
        line-height: 1.08;
        text-align: center;
        margin-bottom: 40px;
    }

    &__box {
        text-align: center;
    }
}

.contributions-jackpot__value {
    padding: 25px;
}

select {
    height: auto; 
    padding: 8.5px 50px 8.5px 20px;
    background-position: calc(100% - 15px) 21px;
}

.j-unset {
    justify-content: unset;
}

.footer .container {
    max-width: 100%;
}