@import '../../styles/vars';

.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    &--lg {
        .social-icons__link {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include b(mobile) {
                width: 24px;
                height: 24px;
            }

            &:hover {
                color: $black;
            }

            svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &__item {
        padding: 0 14px;
        @include b(tablet) {
            padding: 0 10px;
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        color: #1e2135;

        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

body.dark footer .social-icons__link {
    color: #fff !important;
}

body.light footer .social-icons__link {
    color: #fff !important;
}

.header__nav-social a {
    font-size: 36px;
}