@import '../../styles/vars';

select {
    display: block;
    width: 100%;
    height: 44px;
    border: 2px solid var(--text-color);
    border-radius: 100px;
    padding: 6px 43px 4px 13px;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Lato';
    font-size: 26px;
    line-height: 1.3;
    font-weight: 700;
    outline: none;
    background-size: 19px 10px;
    background-position: calc(100% - 15px) 15px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='10' viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.07354 9.07352L0 0H18.1422L9.07354 9.07352Z' fill='white'/%3E%3C/svg%3E");
    @include b(mobile) {
        font-size: 20px;
    }
}

option {
    color: $black;
}

select::-ms-expand {
    display: none;
}

@media only screen and (max-width: 559px) {
    select {
        font-size: 20px;
    }
}