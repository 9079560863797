@import '../../styles/vars';

.switch {
    line-height: 0;

    input {
        display: none;

        &:checked {

            & + span {

                &:before {
                    left: 50px;
                    @include b(mobile) {
                        left: 26px;
                    }
                }
            }
        }
    }

    span {
        display: inline-block;
        width: 98px;
        height: 50px;
        border: 3px solid $black;
        border-radius: 100px;
        position: relative;
        cursor: pointer;
        @include b(mobile) {
            width: 51px;
            height: 27px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 39px;
            height: 39px;
            border-radius: 50%;
            left: 3px;
            top: 3px;
            background-color: $black;
            transition: all .3s;
            @include b(mobile) {
                width: 17px;
                height: 17px;
                top: 2px;
                left: 2px;
            }
        }
    }

    &--sm {

        input {
            &:checked {

                & + span {
    
                    &:before {
                        left: 26px;
                    }
                }
            }
        }

        span {
            width: 51px;
            height: 27px;

            &:before {
                width: 17px;
                height: 17px;
                top: 2px;
                left: 2px;
            }
        }
    }
}