@import '../../styles/vars';

.btn {
    font-family: 'Lato';
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
    padding: 6px 10px;
    border: 2px solid var(--text-color);
    color: var(--text-color);
    background-color: transparent;
    border-radius: 100px;
    display: inline-block;
    transition: all .3s;
    cursor: pointer;

    &:hover {
        box-shadow: 2px 2px 20px rgba(255, 255, 255, 50%)
    }

    &--sm {
        font-size: 18px;
        padding: 10px 25px;
    }

    &--lg {
        padding: 12px 20px;
        @include b(mobile) {
            font-size: 20px;
            padding: 10px 20px;
        }
    }
}