@import '../../styles/vars';

.six-jackpot {
    border-bottom: 3px solid $black;

    &--reverse {
        .six-jackpot__outer {
            flex-direction: row-reverse;
        }
    }

    &--cols {
        .six-jackpot__outer {
            flex-wrap: wrap;
            @include b(tablet) {
                display: flex;
                flex-direction: column;
            }

            .six-jackpot__box {
                @include b(tablet) {
                    order: 3;
                }
            }
        }
    }

    &__outer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 48px 30px;
        @include b(tablet) {
            display: block;
            text-align: center;
        }
    }

    &__title {
        font-size: 98px;
        line-height: 1;
        color: $black;
        @include b(tablet) {
            margin-bottom: 30px;
        }
        @include b(mobile) {
            font-size: 58px;
        }
    }

    &__info {
        font-size: 36px;
        line-height: 1.08;
        letter-spacing: -0.01em;
        width: 50%;
        @include b(tablet) {
            width: 100%;
            margin-bottom: 26px;
            order: 1;
        }
        @include b(mobile) {
            font-size: 28px;
            line-height: 1.14;
            letter-spacing: -0.01em;
        }
    }

    &__label {
        text-align: center;
        margin-bottom: 10px;
    }

    &__wrap {
        display: flex;
        align-items: center;
        @include b(tablet) {
            justify-content: center;
        }
    }

    &__value {
        border: 2px solid var(--text-color);
        height: 44px;
        border-radius: 100px;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        padding: 4px 20px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        @include b(mobile) {
            font-size: 20px;
        }
    }
}

.six-jackpot__value {
    padding: 25px;
}

select {
    height: auto; 
    padding: 8.5px 43px 8.5px 20px;
    background-position: calc(100% - 15px) 21px;
}

.via-text {
    margin: 0px;
    line-height: 2;
    padding-right: 15px;
}

.d-flex {
    display: inline-flex;
}

a.bold-text {
    font-weight: 800;
    border-bottom: 2px solid;
}

a.bold-text:hover {
    color: #1D2136;
}

@media only screen and (max-width: 559px) {
    .d-flex {
        display: block;
    }
    .via-text {
        margin: 0px;
        line-height: 1.25;
        padding-right: 0;
        padding-bottom: 15px;
        font-size: 26px;
        font-weight: 400;
        padding-top: 15px;
    }
}