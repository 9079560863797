@import '../../styles/vars';

.footer {
    background-color: $black;
    color: $white;

    &__box {
        display: flex;
        align-items: flex-start;
        @include b(mobile) {
            display: block;
        }
    }

    &__nav {
        width: 43%;
        display: flex;
        flex-wrap: wrap;
        @include b(mobile) {
            width: 100%;
        }

        &-item {
            width: 50%;
            border-bottom: 3px solid $white;
            border-right: 3px solid $white;
            position: relative;

            &:nth-child(even) {
                &:before {
                    position: absolute;
                    content: '';
                    height: 3px;
                    background-color: $white;
                    bottom: -3px;
                    right: 0;
                    left: -50vw;
                    @include b(tablet) {
                        display: none;
                    }
                }
            }

            &:last-child {
                border-right: none;
            }
        }

        &-link {
            color: $white;
            height: 57px;
            display: flex;
            align-items: center;
            padding: 5px 30px;
            font-weight: 700;
            @include b(mobile) {
                text-align: center;
                font-size: 16px;
                justify-content: center;
                text-align: center;
            }

            &:hover {
                color: #9E94FF;
            }
        }
    }

    &__inner {
        width: 57%;
        @include b(mobile) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &-top {
            border-bottom: 3px solid $white;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 5px 30px;
            @include b(mobile) {
                order: 2;
                width: 50%;
                justify-content: center;
            }

            a {
                color: $white;
                line-height: 0;

                svg {
                    width: 192px;
                    max-width: 100%;
                }
            }
        }

        &-logo {
            height: 180px;
            border-bottom: 3px solid $white;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include b(mobile) {
                width: 100%;
                height: 166px;
                order: 1;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background-color: $white;
                left: 0;
                bottom: -3px;
                right: -50vw;
                @include b(tablet) {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                content: '';
                height: 3px;
                background-color: $white;
                left: 0;
                top: -3px;
                right: -50vw;
                @include b(tablet) {
                    display: none;
                }
            }
        }

        &-bottom {
            display: flex;
            border-bottom: 3px solid $white;
            @include b(mobile) {
                order: 3;
                width: 50%;
            }
        }

        &-col {
            height: 57px;
            border-right: 3px solid $white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 5px 30px;
            width: 56.5%;
            position: relative;
            @include b(mobile) {
                width: 50%;
                border-right: none;
                display: none;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background-color: $white;
                left: 0;
                bottom: -3px;
                right: -50vw;
                @include b(tablet) {
                    display: none;
                }
            }
        }

        &-social {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 43.5%;
            height: 57px;
            @include b(mobile) {
                width: 100%;
            }
        }
    }

    &__foot {
        display: flex;
        @include b(mobile) {
            flex-wrap: wrap;
        }
    }

    &__copy {
        width: 75%;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 5px 30px;
        color: $white;
        font-size: 14px;
        line-height: 1.43;
        @include b(mobile) {
            width: 100%;
            order: 2;
            border-top: 3px solid $white;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            height: auto;
            padding: 20px 30px;
        }
    }

    &__policy {
        width: 45.5%;
        display: flex;
        @include b(mobile) {
            order: 1;
            width: 100%;
        }

        &-col {
            width: 33.33%;
            border-left: 3px solid $white;
            text-align: center;
            @include b(mobile) {
                &:first-child {
                    border-left: none;
                }
            }
        }

        &-link {
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 56px;
            font-size: 14px;

            &:hover {
                color: #9E94FF;
            }
        }
    }
}

.footer__inner-logo {
    overflow: hidden;
}

/* Mobile Landscape */
@media (min-width: 560px) and (max-width: 759px) {
    .footer__inner-logo {
        height: 200px;
    }
}

@media only screen and (max-width: 559px) {
    .footer__inner-logo {
        width: 100%;
        height: auto;
        padding: 0px 30px;
    }
    .footer__inner-logo img {
        margin: 15px 0px 10px 0px;
        height: auto;
    }
    .m-border {
        border-right: 0px;
    }    
    .footer__inner-bottom {
        order: 1;
        width: 100%;
    }
    .footer__inner-top {
        order: 2;
        width: 100%;
    }
    .footer__inner-col {
        height: 70px;
    }
    .footer__inner-social {
        height: 100%;
    }
    .footer__inner-col {
        display: block;
    }
    .footer__nav-link {
        padding: 5px;
    }
}
