@import '../../styles/vars';

.form-box {
    position: relative;
    width: 100%;

    &--default {
        .form-box__field {
            padding: 6px 13px 4px 13px;
            height: 44px;
            border: 2px solid var(--text-color);
            border-radius: 100px;
            color: var(--text-color);
            font-size: 26px;
            line-height: 1.3;
            font-weight: 700;
            @include b(mobile) {
                font-size: 20px;
            }
        }
    }

    &__label {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 36px;
        line-height: 1.08;
        color: $black;
        cursor: text;
        @include b(mobile) {
            font-size: 18px;
        }
    }

    &__field {
        display: block;
        width: 100%;
        height: 115px;
        border: none;
        outline: none;
        padding: 0 15px 0 200px;
        background-color: transparent;
        transition: all .3s;
        font-size: 36px;
        color: var(--text-color);
        font-family: 'Lato';
        @include b(mobile) {
            height: 70px;
            padding: 0 15px 0 120px;
            font-size: 18px;
        }

        &::placeholder {
            color: var(--text-color);
            opacity: .8;
        }

        &:focus {
            background-color: #7D6EFF;
        }
    }

    &--textarea {
        display: flex;
        flex-direction: column-reverse;

        .form-box__label {
            position: static;
            padding: 30px 30px;
            transform: translateY(0);
            transition: all .3s;
            @include b(mobile) {
                padding-bottom: 20px;
            }
        }

        .form-box__field {
            height: 250px;
            padding: 0 15px 30px 30px;
            font-size: 18px;
            resize: none;

            &:focus {

                & + .form-box__label {
                    background-color: #7D6EFF;
                }
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.form-box--default .form-box__field {
    padding: 12px 25px;
    font-size: 21px;
    height: auto;
}

.payment__row select {
    font-size: 21px;
    padding: 12px 25px;
}