.dashboard-styles {
    padding: 100px 0px 125px 0px;
    //background: #5950f5;
    .row {
        &.row-01 {
            padding-bottom: 75px;
        }
    }
    .block {
        border: 3px solid #1e2135 !important;
        border-radius: 35px;
        padding: 25px;
        position: relative;
        height: 100%;
        min-height: 200px;
        p {
            font-size: 28px;
            margin-bottom: 5px;
        }
        p.block-title {
            font-weight: 800;
            font-size: 28px;
            margin-bottom: 25px;        
        }
        .fiat-amount {
            position: absolute;
            bottom: 25px;
            font-size: 42px;
        }
    }

    .links {
        padding-left: 25px;
        a {
            font-size: 32px;
            font-weight: 800;
        }
        a:hover {
            text-shadow: 2px 2px 20px rgba(255,255,255,.75);
        }
        span {
            margin-right: 10px;
        }
    }

    .valign {
        margin: auto !important;
        height: 100% !important;
    }

    .tab-table {
        width: 98%;
        margin: auto;
    }

    .currency-selector {
        display: inline-flex;
        border-radius: 100px;
        border: 2px solid;
        padding: 5px 12px;
        width: 165px;
        position: absolute;
        margin-top: 5px;
        right: 25px;
        h4 {
            font-size: 16px;
            line-height: 18px;
            padding-right: 5px;
        }
        select {
            font-size: 16px;
            border: 0px;
            padding: 0px;
            padding-left: 1px;
            background-position: center right;
        }
    }

    .text-start {
        text-align: left !important;
    }

    .text-end {
        text-align: right !important;
    }

    .move-right {
        margin-left: auto !important;
    }

    .move-left {
        margin-right: auto !important;
    }

    .p-0 {
        padding: 0px !important;
    }

    .flex-inline {
        display: inline-flex;
        p {
            line-height: 2.25;
            margin-right: 10px;
        }
    }

    #cryptoCurrencyList {
        font-size: 16px;
        padding: 5px 30px 5px 15px;
        background-position: calc(100% - 15px) 14px;
    }

    .overview-block {
        border: 2px solid #000;
        border-radius: 35px;
        padding: 50px 35px;
        position: relative;
        height: 100%;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: 800;
            color: #000;
        }
        span {
            font-size: 48px;
            font-weight: 800;
        }
        p {
            font-weight: 600;
            opacity: .65;
        }
    }

    .wallet-block {
        margin-top: 50px;
        .circle-icon {
            background: rgba(0,0,0,.5);
            color: #ffffff;
            padding: 25px;
            width: 75px;
            height: 75px;
            border-radius: 100px;
            i {
                font-size: 24px;
            }
        }
        .wallet-name {
            font-size: 24px;
            font-weight: 800;
        }
        .wallet-token {
            opacity: 0.65;
        }
        .label {
            padding: 6px 15px;
            font-size: 14px;
            background: rgba(0, 0, 0, .5);
            color: #ffffff;
            border-radius: 50px;
        }
        .copylink {
            margin-left: 5px;
            color: #000000 !important;
        }
        .wallet-details {
            p {
                //color: #d0d0d0;
                span {
                    //color: #ffffff;
                    margin-left: 15px;
                    font-weight: 800;
                }
            }
            .more-link {
                font-size: 14px;
                line-height: 21px;
                i {
                    font-size: 24px;
                }
            }
        }
    }
        
    .nav-tabs .nav-link {
        margin-bottom: -1px;
        background: none;
        border: none;
        border-radius: 35px 35px 0px 0px;
        padding: 25px 35px;
        color: #fff;
        background-color: #1f2136;
        opacity: .5;
     }

    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        color: #fff;
        background-color: #1f2136;
        opacity: .5;
        cursor: pointer;
        isolation: isolate;
     }

    .nav-tabs .nav-link.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #fff;
        background-color: #1f2136;
        border: none;
        opacity: 1;
        box-shadow: 0px -11px 20px 0px rgba(0,0,0,.15);
    }

    .tab-content {
        position: relative;
        background: rgba(255,255,255,.1);
    }
        
    .tab-content > .tab-pane {
        display: none;
        padding: 50px 35px;
        box-shadow: 0px 1px 20px 0px rgba(0,0,0,.15);
    }

    .tab-content > .active {
        display: block;
    }
        
    .fade {
        transition: opacity 0.15s linear;
    }
    @media (prefers-reduced-motion: reduce) {
        .fade {
            transition: none;
        }
    }

    .fade:not(.show) {
        opacity: 0;
    }
        
    .collapse:not(.show) {
        display: none;
    }
        
    .collapsing {
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease;
    }


    .container,
    .container-fluid,
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm {
      width: 100%;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
      margin-right: auto;
      margin-left: auto;
    }
    
    @media (min-width: 576px) {
      .container-sm, .container {
        max-width: 540px;
      }
    }
    @media (min-width: 768px) {
      .container-md, .container-sm, .container {
        max-width: 720px;
      }
    }
    @media (min-width: 992px) {
      .container-lg, .container-md, .container-sm, .container {
        max-width: 960px;
      }
    }
    @media (min-width: 1200px) {
      .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px;
      }
    }
    @media (min-width: 1400px) {
      .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px;
      }
    }
    .row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--bs-gutter-y) * -1);
      margin-right: calc(var(--bs-gutter-x) * -.5);
      margin-left: calc(var(--bs-gutter-x) * -.5);
    }
    .row > * {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-top: var(--bs-gutter-y);
    }
    
    .col {
      flex: 1 0 0%;
    }
    
    .row-cols-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    
    .row-cols-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    
    .row-cols-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    
    .row-cols-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    
    .row-cols-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    
    .row-cols-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    
    .row-cols-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    
    @media (min-width: 576px) {
      .col-sm {
        flex: 1 0 0%;
      }
    
      .row-cols-sm-auto > * {
        flex: 0 0 auto;
        width: auto;
      }
    
      .row-cols-sm-1 > * {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .row-cols-sm-2 > * {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .row-cols-sm-3 > * {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .row-cols-sm-4 > * {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .row-cols-sm-5 > * {
        flex: 0 0 auto;
        width: 20%;
      }
    
      .row-cols-sm-6 > * {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    }
    @media (min-width: 768px) {
      .col-md {
        flex: 1 0 0%;
      }
    
      .row-cols-md-auto > * {
        flex: 0 0 auto;
        width: auto;
      }
    
      .row-cols-md-1 > * {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .row-cols-md-2 > * {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .row-cols-md-3 > * {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .row-cols-md-4 > * {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .row-cols-md-5 > * {
        flex: 0 0 auto;
        width: 20%;
      }
    
      .row-cols-md-6 > * {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    }
    @media (min-width: 992px) {
      .col-lg {
        flex: 1 0 0%;
      }
    
      .row-cols-lg-auto > * {
        flex: 0 0 auto;
        width: auto;
      }
    
      .row-cols-lg-1 > * {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .row-cols-lg-2 > * {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .row-cols-lg-3 > * {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .row-cols-lg-4 > * {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .row-cols-lg-5 > * {
        flex: 0 0 auto;
        width: 20%;
      }
    
      .row-cols-lg-6 > * {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    }
    @media (min-width: 1200px) {
      .col-xl {
        flex: 1 0 0%;
      }
    
      .row-cols-xl-auto > * {
        flex: 0 0 auto;
        width: auto;
      }
    
      .row-cols-xl-1 > * {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .row-cols-xl-2 > * {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .row-cols-xl-3 > * {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .row-cols-xl-4 > * {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .row-cols-xl-5 > * {
        flex: 0 0 auto;
        width: 20%;
      }
    
      .row-cols-xl-6 > * {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    }
    @media (min-width: 1400px) {
      .col-xxl {
        flex: 1 0 0%;
      }
    
      .row-cols-xxl-auto > * {
        flex: 0 0 auto;
        width: auto;
      }
    
      .row-cols-xxl-1 > * {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .row-cols-xxl-2 > * {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .row-cols-xxl-3 > * {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .row-cols-xxl-4 > * {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .row-cols-xxl-5 > * {
        flex: 0 0 auto;
        width: 20%;
      }
    
      .row-cols-xxl-6 > * {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    }
    .col-auto {
      flex: 0 0 auto;
      width: auto;
    }
    
    .col-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    
    .col-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    
    .col-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    
    .col-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    
    .col-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    
    .col-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    
    .col-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    
    .col-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    
    .col-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    
    .col-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    
    .col-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    
    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    
    .offset-1 {
      margin-left: 8.33333333%;
    }
    
    .offset-2 {
      margin-left: 16.66666667%;
    }
    
    .offset-3 {
      margin-left: 25%;
    }
    
    .offset-4 {
      margin-left: 33.33333333%;
    }
    
    .offset-5 {
      margin-left: 41.66666667%;
    }
    
    .offset-6 {
      margin-left: 50%;
    }
    
    .offset-7 {
      margin-left: 58.33333333%;
    }
    
    .offset-8 {
      margin-left: 66.66666667%;
    }
    
    .offset-9 {
      margin-left: 75%;
    }
    
    .offset-10 {
      margin-left: 83.33333333%;
    }
    
    .offset-11 {
      margin-left: 91.66666667%;
    }
    
    .g-0,
    .gx-0 {
      --bs-gutter-x: 0;
    }
    
    .g-0,
    .gy-0 {
      --bs-gutter-y: 0;
    }
    
    .g-1,
    .gx-1 {
      --bs-gutter-x: 0.25rem;
    }
    
    .g-1,
    .gy-1 {
      --bs-gutter-y: 0.25rem;
    }
    
    .g-2,
    .gx-2 {
      --bs-gutter-x: 0.5rem;
    }
    
    .g-2,
    .gy-2 {
      --bs-gutter-y: 0.5rem;
    }
    
    .g-3,
    .gx-3 {
      --bs-gutter-x: 1rem;
    }
    
    .g-3,
    .gy-3 {
      --bs-gutter-y: 1rem;
    }
    
    .g-4,
    .gx-4 {
      --bs-gutter-x: 1.5rem;
    }
    
    .g-4,
    .gy-4 {
      --bs-gutter-y: 1.5rem;
    }
    
    .g-5,
    .gx-5 {
      --bs-gutter-x: 3rem;
    }
    
    .g-5,
    .gy-5 {
      --bs-gutter-y: 3rem;
    }
    
    @media (min-width: 576px) {
      .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
      }
    
      .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
      }
    
      .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
      }
    
      .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
    
      .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    
      .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
      }
    
      .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    
      .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
      }
    
      .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
      }
    
      .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .offset-sm-0 {
        margin-left: 0;
      }
    
      .offset-sm-1 {
        margin-left: 8.33333333%;
      }
    
      .offset-sm-2 {
        margin-left: 16.66666667%;
      }
    
      .offset-sm-3 {
        margin-left: 25%;
      }
    
      .offset-sm-4 {
        margin-left: 33.33333333%;
      }
    
      .offset-sm-5 {
        margin-left: 41.66666667%;
      }
    
      .offset-sm-6 {
        margin-left: 50%;
      }
    
      .offset-sm-7 {
        margin-left: 58.33333333%;
      }
    
      .offset-sm-8 {
        margin-left: 66.66666667%;
      }
    
      .offset-sm-9 {
        margin-left: 75%;
      }
    
      .offset-sm-10 {
        margin-left: 83.33333333%;
      }
    
      .offset-sm-11 {
        margin-left: 91.66666667%;
      }
    
      .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0;
      }
    
      .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0;
      }
    
      .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem;
      }
    
      .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem;
      }
    
      .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem;
      }
    
      .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem;
      }
    
      .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem;
      }
    
      .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem;
      }
    
      .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem;
      }
    
      .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem;
      }
    
      .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem;
      }
    
      .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem;
      }
    }
    @media (min-width: 768px) {
      .col-md-auto {
        flex: 0 0 auto;
        width: auto;
      }
    
      .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
      }
    
      .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
      }
    
      .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
    
      .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    
      .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
      }
    
      .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    
      .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
      }
    
      .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
      }
    
      .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .offset-md-0 {
        margin-left: 0;
      }
    
      .offset-md-1 {
        margin-left: 8.33333333%;
      }
    
      .offset-md-2 {
        margin-left: 16.66666667%;
      }
    
      .offset-md-3 {
        margin-left: 25%;
      }
    
      .offset-md-4 {
        margin-left: 33.33333333%;
      }
    
      .offset-md-5 {
        margin-left: 41.66666667%;
      }
    
      .offset-md-6 {
        margin-left: 50%;
      }
    
      .offset-md-7 {
        margin-left: 58.33333333%;
      }
    
      .offset-md-8 {
        margin-left: 66.66666667%;
      }
    
      .offset-md-9 {
        margin-left: 75%;
      }
    
      .offset-md-10 {
        margin-left: 83.33333333%;
      }
    
      .offset-md-11 {
        margin-left: 91.66666667%;
      }
    
      .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0;
      }
    
      .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0;
      }
    
      .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem;
      }
    
      .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem;
      }
    
      .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem;
      }
    
      .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem;
      }
    
      .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem;
      }
    
      .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem;
      }
    
      .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem;
      }
    
      .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem;
      }
    
      .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem;
      }
    
      .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem;
      }
    }
    @media (min-width: 992px) {
      .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
      }
    
      .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
      }
    
      .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
      }
    
      .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
    
      .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    
      .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
      }
    
      .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    
      .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
      }
    
      .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
      }
    
      .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .offset-lg-0 {
        margin-left: 0;
      }
    
      .offset-lg-1 {
        margin-left: 8.33333333%;
      }
    
      .offset-lg-2 {
        margin-left: 16.66666667%;
      }
    
      .offset-lg-3 {
        margin-left: 25%;
      }
    
      .offset-lg-4 {
        margin-left: 33.33333333%;
      }
    
      .offset-lg-5 {
        margin-left: 41.66666667%;
      }
    
      .offset-lg-6 {
        margin-left: 50%;
      }
    
      .offset-lg-7 {
        margin-left: 58.33333333%;
      }
    
      .offset-lg-8 {
        margin-left: 66.66666667%;
      }
    
      .offset-lg-9 {
        margin-left: 75%;
      }
    
      .offset-lg-10 {
        margin-left: 83.33333333%;
      }
    
      .offset-lg-11 {
        margin-left: 91.66666667%;
      }
    
      .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0;
      }
    
      .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0;
      }
    
      .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem;
      }
    
      .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem;
      }
    
      .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem;
      }
    
      .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem;
      }
    
      .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem;
      }
    
      .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem;
      }
    
      .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem;
      }
    
      .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem;
      }
    
      .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem;
      }
    
      .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem;
      }
    }
    @media (min-width: 1200px) {
      .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
      }
    
      .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
      }
    
      .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
      }
    
      .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
    
      .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    
      .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
      }
    
      .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    
      .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
      }
    
      .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
      }
    
      .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .offset-xl-0 {
        margin-left: 0;
      }
    
      .offset-xl-1 {
        margin-left: 8.33333333%;
      }
    
      .offset-xl-2 {
        margin-left: 16.66666667%;
      }
    
      .offset-xl-3 {
        margin-left: 25%;
      }
    
      .offset-xl-4 {
        margin-left: 33.33333333%;
      }
    
      .offset-xl-5 {
        margin-left: 41.66666667%;
      }
    
      .offset-xl-6 {
        margin-left: 50%;
      }
    
      .offset-xl-7 {
        margin-left: 58.33333333%;
      }
    
      .offset-xl-8 {
        margin-left: 66.66666667%;
      }
    
      .offset-xl-9 {
        margin-left: 75%;
      }
    
      .offset-xl-10 {
        margin-left: 83.33333333%;
      }
    
      .offset-xl-11 {
        margin-left: 91.66666667%;
      }
    
      .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0;
      }
    
      .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0;
      }
    
      .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem;
      }
    
      .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem;
      }
    
      .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem;
      }
    
      .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem;
      }
    
      .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem;
      }
    
      .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem;
      }
    
      .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem;
      }
    
      .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem;
      }
    
      .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem;
      }
    
      .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem;
      }
    }
    @media (min-width: 1400px) {
      .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
      }
    
      .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
      }
    
      .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
      }
    
      .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
    
      .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    
      .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
      }
    
      .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    
      .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
      }
    
      .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
      }
    
      .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    
      .offset-xxl-0 {
        margin-left: 0;
      }
    
      .offset-xxl-1 {
        margin-left: 8.33333333%;
      }
    
      .offset-xxl-2 {
        margin-left: 16.66666667%;
      }
    
      .offset-xxl-3 {
        margin-left: 25%;
      }
    
      .offset-xxl-4 {
        margin-left: 33.33333333%;
      }
    
      .offset-xxl-5 {
        margin-left: 41.66666667%;
      }
    
      .offset-xxl-6 {
        margin-left: 50%;
      }
    
      .offset-xxl-7 {
        margin-left: 58.33333333%;
      }
    
      .offset-xxl-8 {
        margin-left: 66.66666667%;
      }
    
      .offset-xxl-9 {
        margin-left: 75%;
      }
    
      .offset-xxl-10 {
        margin-left: 83.33333333%;
      }
    
      .offset-xxl-11 {
        margin-left: 91.66666667%;
      }
    
      .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0;
      }
    
      .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0;
      }
    
      .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem;
      }
    
      .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem;
      }
    
      .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem;
      }
    
      .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem;
      }
    
      .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem;
      }
    
      .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem;
      }
    
      .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem;
      }
    
      .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem;
      }
    
      .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem;
      }
    
      .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem;
      }
    }
}

/* iPad Pro */
@media (min-width: 992px) and (max-width: 1199px) {
    .dashboard-styles .block p.block-title {
        font-size: 28px !important;
    }
    .dashboard-styles .currency-selector {
        padding: 5px 12px;
        width: 100% !important;
        margin-top: 0px !important;
        position: relative;
        right: auto;
    }
    .dashboard-styles .block {
        padding: 25px 15px 25px 25px !important;
    }
    .dashboard-styles .links a {
        font-size: 28px !important;
    }
    .dashboard-styles .block p {
        font-size: 21px !important;
    }
    .dashboard-styles .block .fiat-amount {
        font-size: 28px !important;
    }
    .dashboard-styles .overview-block h3 {
        font-size: 24px !important;
    }
    .dashboard-styles .overview-block span {
        font-size: 32px !important;
    }
    .dashboard-styles .wallet-block .wallet-token {
        font-size: 15px !important;
    }
    .dashboard-styles .wallet-block .wallet-details p, .dashboard-styles .wallet-block .label {
        font-size: 15px !important;
    }
    .dashboard-styles .wallet-block .circle-icon {
        padding: 20px !important;
        width: 65px !important;
        height: 65px !important;
        border-radius: 100px !important;
    }
}

/* iPad */
@media (min-width: 768px) and (max-width: 991px) {
    .dashboard-styles .container {
        max-width: 100% !important;
    }
    .dashboard-styles .block p.block-title {
        font-size: 28px !important;
    }
    .dashboard-styles .currency-selector {
        padding: 5px 12px;
        width: 100% !important;
        margin-top: 0px !important;
        position: relative;
        right: auto;
    }
    .dashboard-styles .block {
        padding: 25px 15px 25px 25px !important;
    }
    .dashboard-styles .links a {
        font-size: 21px !important;
    }
    .dashboard-styles .block p {
        font-size: 21px !important;
    }
    .dashboard-styles .block .fiat-amount {
        font-size: 28px !important;
    }
    .dashboard-styles .overview-block h3 {
        font-size: 21px !important;
    }
    .dashboard-styles .overview-block span {
        font-size: 32px !important;
    }
    .dashboard-styles .wallet-block .wallet-token {
        font-size: 12px !important;
    }
    .dashboard-styles .wallet-block .wallet-details p, .dashboard-styles .wallet-block .label {
        font-size: 12px !important;
    }
    .dashboard-styles .wallet-block .circle-icon {
        padding: 12px !important;
        width: 45px !important;
        height: 45px !important;
        border-radius: 100px !important;
    }
    .dashboard-styles .wallet-block .circle-icon i {
        font-size: 18px;
    }
    .dashboard-styles .wallet-block .wallet-name {
        font-size: 21px;
    }
    .dashboard-styles .overview-block p {
        font-size: 16px;
    }
}

/* Mobile Landscape */
@media (min-width: 560px) and (max-width: 767px) {
    .dashboard-styles .container {
        max-width: 100% !important;
    }
    .dashboard-styles .row-01 .col-md-4 {
        margin-bottom: 25px;
    }
    .dashboard-styles .currency-selector {
        padding: 5px 25px !important;
        width: 100% !important;
        margin-top: 10px !important;
        position: relative;
        right: auto;
    }
    .dashboard-styles .block {
        height: 100% !important;
        min-height: auto !important;
        padding: 45px 25px;
    }
    .dashboard-styles .block .fiat-amount {
        position: relative;
        bottom: 0;
        font-size: 54px;
    }
    .dashboard-styles .currency-selector h4 {
        font-size: 26px;
        line-height: 54px;
    }
    .dashboard-styles .currency-selector select {
        font-size: 26px;
    }
    .dashboard-styles .block p.block-title {
        font-weight: 800;
        font-size: 42px;
    }
    .dashboard-styles .links a {
        font-size: 42px;
        font-weight: 800;
    }
    .dashboard-styles .links {
        padding: 45px 25px;
    }
    .dashboard-styles .row.row-01 {
        padding-bottom: 50px;
    }
    .dashboard-styles .nav-tabs .nav-link {
        font-size: 32px;
    }
    .dashboard-styles .flex-inline p {
        line-height: 2.25;
        font-size: 26px;
        line-height: 54px;
    }
    .dashboard-styles #cryptoCurrencyList {
        font-size: 26px;
        background-position: calc(100% - 15px) 24px;
    }
    .dashboard-styles .overview-block h3 {
        font-size: 48px;
        margin-bottom: 45px;
    }
    .dashboard-styles .overview-block span {
        font-size: 62px;
        font-weight: 800;
    }
    .center-mobile {
        text-align: center;
    }
    .dashboard-styles .wallet-block .wallet-name {
        font-size: 32px;
    }
    .dashboard-styles .wallet-block .label {
        font-size: 18px;
        width: 160px;
        position: absolute;
        right: 25px;
    }
    .dashboard-styles .wallet-block .wallet-token {
        font-size: 21px;
        width: 400px;
    }
    .dashboard-styles .p-0.mobile-pl {
        padding-left: 15px !important;
    }
    .dashboard-styles .mobile-pb {
        padding-bottom: 25px !important;
    }
    .dashboard-styles .mobile-text-left {
        text-align: left !important;
    }
    .dashboard-styles .wallet-block .wallet-details p {
        font-size: 30px;
    }
    .dashboard-styles .wallet-block .wallet-details .more-link {
        font-size: 18px;
        line-height: 32px;
    }
    .dashboard-styles .block p {
        font-size: 36px;
    }
    .wallet-details .col-2{
        position: absolute;
        margin-top: 50px;
        right: 25px;
    }
    .dashboard-styles .wallet-block .circle-icon {
        padding: 14px;
        width: 50px;
        height: 50px;
    }
    .dashboard-styles .wallet-block .circle-icon i {
        font-size: 21px;
    }
    .wallet-details .date {
        margin-left: 50px;
        width: 225px;
    }
    .wallet-details .amount {
        padding-left: 20px;
    }
    .dashboard-styles .tab-content > .active {
        padding-bottom: 150px;
    }
    .dashboard-styles .wallet-block {
        margin-top: 125px;
    }
    .dashboard-styles .overview-block {
        margin-bottom: -50px;
    }
    .dashboard-styles .nav-tabs .nav-link {
        font-size: 26px;
    }
}

@media screen and (min-width:992px) {
}

/* Mobile */
@media only screen and (max-width: 559px) {
    .col-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    .dashboard-styles .container {
        max-width: 100% !important;
    }
    .dashboard-styles .row-01 .col-md-4 {
        margin-bottom: 25px;
    }
    .dashboard-styles .currency-selector {
        padding: 5px 25px !important;
        width: 100% !important;
        margin-top: 10px !important;
        position: relative;
        right: auto;
    }
    .dashboard-styles .block {
        height: 100% !important;
        min-height: auto !important;
        padding: 45px 25px;
    }
    .dashboard-styles .block .fiat-amount {
        position: relative;
        bottom: 0;
        font-size: 54px;
    }
    .dashboard-styles .currency-selector h4 {
        font-size: 26px;
        line-height: 54px;
    }
    .dashboard-styles .currency-selector select {
        font-size: 26px;
    }
    .dashboard-styles .block p.block-title {
        font-weight: 800;
        font-size: 32px;
    }
    .dashboard-styles .links a {
        font-size: 28px;
        font-weight: 800;
    }
    .dashboard-styles .links {
        padding: 45px 25px;
    }
    .dashboard-styles .row.row-01 {
        padding-bottom: 50px;
    }
    .dashboard-styles .nav-tabs .nav-link {
        font-size: 32px;
    }
    .dashboard-styles .flex-inline p {
        line-height: 2.25;
        font-size: 26px;
        line-height: 54px;
    }
    .dashboard-styles #cryptoCurrencyList {
        font-size: 26px;
        background-position: calc(100% - 15px) 24px;
    }
    .dashboard-styles .overview-block h3 {
        margin-bottom: 25px;
    }
    .dashboard-styles .overview-block span {
        font-size: 48px;
        font-weight: 800;
    }
    .center-mobile {
        text-align: center;
    }
    .dashboard-styles .wallet-block .wallet-name {
        font-size: 32px;
    }
    .dashboard-styles .wallet-block .label {
        font-size: 18px;
        width: 160px;
        position: absolute;
        right: 25px;
    }
    .dashboard-styles .wallet-block .wallet-token {
        font-size: 21px;
    }
    .dashboard-styles .p-0.mobile-pl {
        padding-left: 15px !important;
    }
    .dashboard-styles .mobile-pb {
        padding-bottom: 15px !important;
    }
    .dashboard-styles .mobile-text-left {
        text-align: left !important;
    }
    .dashboard-styles .wallet-block .wallet-details p {
        font-size: 30px;
    }
    .dashboard-styles .wallet-block .wallet-details .more-link {
        font-size: 18px;
        line-height: 32px;
    }
    .dashboard-styles .block p {
        font-size: 26px;
    }
    .dashboard-styles .overview-block {
        padding: 50px 25px;
    }
    .dashboard-styles .nav-tabs .nav-link {
        font-size: 18px;
    }
    .dashboard-styles .tab-content > .tab-pane {
        padding: 50px 15px;
    }
    .dashboard-styles .overview-block h3 {
        font-size: 24px;
    }    
    .dashboard-styles .overview-block span {
        font-size: 38px;
    }
    .dashboard-styles .overview-block p {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
    }
    .dashboard-styles .wallet-block .wallet-name {
        font-size: 18px;
    }
    .dashboard-styles .wallet-block .wallet-token {
        font-size: 14px;
        width: 400px;
    }
    .dashboard-styles .wallet-block .circle-icon {
        padding: 11px;
        width: 40px;
        height: 40px;
    }
    .dashboard-styles .wallet-block .circle-icon i {
        font-size: 18px;
    }
    .dashboard-styles .wallet-block .wallet-details p {
        font-size: 16px;
    }
    .dashboard-styles .wallet-block .wallet-details .more-link {
        font-size: 10px;
        line-height: 20px;
    }
    .dashboard-styles .wallet-block .label {
        font-size: 10px;
        width: 100px;
    }
    .dashboard-styles .wallet-block .wallet-token {
        margin-top: 5px;
    }
    .dashboard-styles .wallet-details .row.text-center.g-0 {
        width: 90%;
        margin: auto;
    }
}

/* iPhone */
@media only screen and (max-width: 375px) {

}

/* Galaxy S5 */
@media only screen and (max-width: 360px) {

}

/* iPhone 5 */
@media only screen and (max-width: 320px) {

}

/* Galaxy Fold */
@media only screen and (max-width: 280px) {

}