@import '../../styles/vars';

.charity-nomination {
    &__wrap {
        display: flex;
        @include b(tablet) {
            display: block;
        }
    }

    .form-box__field {
        padding-left: 320px;
        @include b(mobile) {
            padding-left: 170px;
        }
    }

    &__col-sm {
        width: 28%;
        @include b(tablet) {
            width: 100%;
            height: 270px;
            background-position: 50% 90%;
        }
    }

    &__col-lg {
        width: 72%;
        border-right: 3px solid $black;
        @include b(tablet) {
            width: 100%;
            border-right: none;
        }
    }

    &__row {
        border-bottom: 3px solid $black;

        &--btn {
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: none;
            @include b(tablet) {
                border-bottom: 3px solid $black;
            }
        }
    }

    &__button {
        width: 100%;
    }
}