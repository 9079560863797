@import '../../styles/vars';

.partners {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 70px;
    @include b(tablet) {
        order: 2;
        padding-top: 0;
        margin-bottom: 56px;
        width: calc(100% + 60px);
        margin: 0 -30px 56px;
    }

    &__swiper {
        padding: 0 30px;
        flex-grow: 1;

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
            }
        }
    }

    &__item {
        height: 100px;
        background-color: red;
    }

    &__prev,
    &__next {
        min-width: 46px;
        max-width: 46px;
        height: 46px;
        cursor: pointer;
        @include b(tablet) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    &__next {
        
        img {
            transform: rotate(180deg);
        }
    }
}

@media (min-width: 600px) and (max-width: 1025px) {
    .partners__prev, .partners__next {
        display: block;
        position: relative;
        margin: auto;
      }
      .partners__swiper {
          padding: 0px;
      }
      .swiper-slide {
          opacity: 1;
      }
}

@media (max-width: 599px) {
    .partners__prev, .partners__next {
      display: block;
      position: relative;
      margin: auto;
    }
    .partners__swiper {
        padding: 0px;
    }
    .swiper-slide {
        opacity: 0;
    }
    .swiper-slide.swiper-slide-active {
        opacity: 1;
    }
    .partners {
        padding: 0px 35px;
        margin-bottom: 35px;
    }
    .partners__prev img, .partners__next img {
        width: 75%;
    }
    .partners__swiper .swiper-slide img {
        max-width: none;
    }
}