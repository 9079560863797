@import '../../styles/vars';

.box-text {
    text-align: center;
    font-size: 36px;
    line-height: 1.08;
    font-weight: 700;
    letter-spacing: -0.01em;
    border-bottom: 3px solid $black;

    &__wrap {
        padding: 80px 30px;
        @include b(mobile) {
            font-size: 28px;
            padding: 40px 30px;
            font-weight: 400;
            line-height: 1.14;
        }

        p {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--how-it-works {
        font-size: 18px;
        font-weight: 400;
    }
}
