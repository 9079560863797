@import '../../styles/vars';

.not-found {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 56px);
    @include b(mobile) {
        min-height: calc(100vh - 107px);
    }

    &__row {
        display: flex;

        &:first-child {
            border-bottom: 3px solid $black;
            flex-grow: 1;
        }
    }

    &__cell {
        width: 78%;
        margin: 0 auto;
        border-right: 3px solid $black;
        border-left: 3px solid $black;
        display: flex;
        justify-content: center;
        align-items: center;
        @include b(mobile) {
            width: 100%;
            border: none;
        }

        img {
            max-width: 100%;
        }
    }

    &__description-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        text-align: center;
        @include b(mobile) {
            flex-direction: column;
            padding: 30px;
        }

        .btn {
            padding: 7px 28px;
            @include b(mobile) {
                width: 100%;
                font-size: 20px;
                line-height: 1.3;
            }
        }
    }

    &__description {
        margin-right: 28px;
        font-size: 36px;
        line-height: 1.08;
        @include b(mobile) {
            margin: 0 0 22px;
            font-size: 18px;
            line-height: 1.11;
        }
    }
}
