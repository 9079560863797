@import '../../styles/vars';

.header {
    border-bottom: 3px solid $black;
    position: relative;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 30px;
        @include b(tablet) {
            flex-wrap: wrap;
            padding: 18px 30px 0;
        }
    }

    &__btn-menu {
        width: 28px;
        height: 24px;
        position: relative;
        cursor: pointer;
        color: $black;
        @include b(tablet) {
            order: 0;
        }

        &.active {

            .header__btn-line {

                &:nth-child(1) {
                    transform: rotate(-45deg);
                    top: 9px;
                }

                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    transform: rotate(45deg);
                    top: 9px;
                }
            }
        }
    }

    &__btn-line {
        width: 28px;
        height: 4px;
        background-color: currentColor;
        position: absolute;
        left: 0;
        transition: all .3s;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 10px;
        }

        &:nth-child(3) {
            top: 20px;
        }
    }

    &__text {
        font-weight: 700;
        line-height: 1.56;
        padding: 0 15px;
        text-align: center;
        position: absolute;
        margin-left: 50px;
        @include b(tablet) {
            font-size: 12px;
            line-height: 1.42;
            border-top: 3px solid $black;
            padding: 8px 0;
            width: calc(100% + 60px);
            margin: 0 -30px;
            order: 3;
            margin-top: 18px;
            position: relative;
        }
    }

    &__box-rigth {
        display: flex;
        align-items: center;
        @include b(tablet) {
            order: 2;
        }
    }

    &__nav {
        position: absolute;
        z-index: 9;
        top: calc(100% + 3px);
        left: 0;
        width: 100%;
        background-color: var(--bg-color);
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        @include b(mobile) {
            top: calc(100% - 35px);
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 46px;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            background: #fff;
        }

        &-col-logo {
            padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-col-sm {
            width: 50%;
            height: 72px;
            border-bottom: 3px solid $black;
            border-right: 3px solid $black;
            position: relative;
            @include b(mobile) {
                height: 56px;
                width: 50%;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background-color: $black;
                bottom: -3px;
                right: 0;
                left: -50vw;
                @include b(tablet) {
                    display: none;
                }
            }
        }

        &-col-lg {
            width: 50%;
            height: 72px;
            border-bottom: 3px solid $black;
            position: relative;
            @include b(mobile) {
                height: 56px;
                width: 50%;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background-color: $black;
                bottom: -3px;
                right: -50vw;
                left: 0;
                @include b(tablet) {
                    display: none;
                }
            }
        }

        &-col-built {
            padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            @include b(mobile) {
                align-items: center;
            }

            a {
                line-height: 0;

                svg {
                    width: 192px;
                    max-width: 100%;
                }
            }
        }

        &-head {
            display: flex;
            @include b(mobile) {
                flex-direction: column-reverse;
            }
            
            .header__nav-col-sm,
            .header__nav-col-lg {
                height: 124px;
                @include b(mobile) {
                    width: 100%;
                    height: 56px;
                }
            }

            .header__nav-col-sm {
                @include b(mobile) {
                    border-right: none;
                    height: 138px;
                }
            }

            .header__nav-col-lg {
                display: flex;
                @include b(mobile) {

                }
            }
        }

        &-social {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 30px;
            @include b(mobile) {
                width: 50%;
                border-right: 3px solid $black;
                border-top: 3px solid #1D2136;
                margin-top: -3px;
            }

            &-item {
                padding: 0 15px;
                @include b(mobile) {
                    padding: 0 7px;
                }

                a {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include b(mobile) {
                        width: 25px;
                        height: 25px;
                        color: $black;
                    }

                    &:hover {
                        color: $black;
                    }

                    svg {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        &-theme {
            width: 58%;
            display: flex;
            justify-content: center;
            align-items: center;
            display: none;
            @include b(mobile) {
                width: 50%;
                border-top: 3px solid $black;
                margin-top: -3px;
                display: flex;
            }

            .img-sun {
                max-width: 46px;
                max-height: 46px;
                @include b(mobile) {
                    max-width: 33px;
                    max-height: 33px;
                }
            }

            .img-moon {
                max-width: 33px;
                max-height: 33px;
                @include b(mobile) {
                    max-width: 24px;
                    max-height: 24px;
                }
            }

            .switch {
                margin: 0 21px;
                @include b(mobile) {
                    margin: 0 15px;
                }
            }

            &--sm {
                display: flex;
                margin-right: 15px;
                @include b(mobile) {
                    width: auto;
                    border-top: none;
                    margin-top: 0;
                    display: none;
                }

                .img-sun {
                    max-width: 33px;
                    max-height: 33px;
                }
    
                .img-moon {
                    max-width: 24px;
                    max-height: 24px;
                }
    
                .switch {
                    margin: 0 15px;
                }
            }
        }

        &-body {
            display: flex;
            flex-wrap: wrap;

            .header__nav-col-sm,
            .header__nav-col-lg {
                display: flex;
                flex-direction: column;
            }
        }

        &-link {
            font-size: 36px;
            line-height: 1.08;
            font-weight: 700;
            letter-spacing: -.01em;
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 5px 10px 5px 30px;
            @include b(mobile) {
                font-size: 16px;
                line-height: 1.25;
                text-align: center;
                justify-content: center;
                padding: 5px;
            }

            &--built {
                @include b(mobile) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 46px;
                }
            }

            &:hover {
                color: $black;
            }
        }
    }
}

/* Mobile Landscape */
@media (min-width: 560px) and (max-width: 759px) {
    .logo-box__wrap {
        height: 200px;
    }
    .header__nav-head .header__nav-col-sm {
        padding: 10px;
    }
}